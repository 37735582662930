import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { anotherTimezoneByUTCOffset, getCountdown } from './market_open_close';

const Timeoffset = -5;

const MarketOpenCloseStatus = () => {
  const [openClose, setOpenClose] = useState(
    getCountdown(anotherTimezoneByUTCOffset(Timeoffset))
  );
  useEffect(() => {
    const runner = setInterval(() => {
      setOpenClose(getCountdown(anotherTimezoneByUTCOffset(Timeoffset)));
    }, 1000);
    return () => clearInterval(runner);
  }, []);

  return (
    <Wrapper id={'market-open-close'}>
      <span className={'ion-android-time'}></span> <span>{openClose[1]}</span>{' '}
      <span>{openClose[0]}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline;
  gap: 4px;
`;

const ClockData = styled.span`
  display: inline-block;
  /* flex-direction: column; */
`;

export default MarketOpenCloseStatus;
