const setSWB = data => ({
  type: 'SET_SWB_DATA',
  payload: data,
});
const setUF = data => ({
  type: 'SET_UF_DATA',
  payload: data,
});

const setFetchData = data => ({
  type: 'SET_FETCH_DATA',
  payload: data,
});
const setChartTicker = data => ({
  type: 'SET_CHART_TICKER',
  payload: data,
});
const setFlowChartData = data => ({
  type: 'SET_CHART_DATA',
  payload: data,
});
const setTickers = data => ({
  type: 'SET_TICKERS',
  payload: data,
});
const setTickersOut = data => ({
  type: 'SET_TICKERS_OUT',
  payload: data,
});
const setSectors = data => ({
  type: 'SET_SECTORS',
  payload: data,
});

const setWlTickers = data => ({
  type: 'SET_WL_TICKERS',
  payload: data,
});

export {
  setSWB,
  setUF,
  setFetchData,
  setChartTicker,
  setFlowChartData,
  setTickers,
  setTickersOut,
  setSectors,
  setWlTickers,
};
