import React, { useState, useEffect } from 'react';
import WindowResizeListener from 'react-window-size-listener';
import styled from 'styled-components';
import App from '../App';
//import ChatLoadIframe from './ChatLoadIframe';
import Box from '@isomorphic/shared/isomorphic/components/utility/box';
import News from '../../../customApp/containers/news';
import { GetDrawerState, SetDrawerState } from './PersistDrawerState';

const Drawer = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [size, setSize] = useState({
    left: '65%',
    right: '35%',
  });

  const [iframeSizes, setIframeSizes] = useState({
    height: 0,
    width: 0,
  });
  useEffect(() => {
    setIsDrawerOpen(() => GetDrawerState());
    if (props.match.path.includes('news')) setIsDrawerOpen(false);
  }, []);

  const handleToggleDrawer = () => {
    SetDrawerState(!isDrawerOpen);
    setIsDrawerOpen(prev => !prev);
  };
  return (
    <DrawerWrapper>
      <WindowResizeListener
        onResize={windowSize => {
          if (windowSize.windowWidth < 900) {
            setSize(() => ({ left: '0%', right: '100%' }));
            setIframeSizes(() => ({
              width: document.getElementById('chatloading').clientWidth,
              height: document.getElementById('chatloading').clientHeight,
            }));
          } else {
            setSize(() => ({ left: '65%', right: '35%' }));
            setIframeSizes(() => ({
              width: document.getElementById('chatloading').clientWidth,
              height: document.getElementById('chatloading').clientHeight,
            }));
          }
        }}
      />
      <DrawerLeftWrapper isDrawerOpen={isDrawerOpen} size={size}>
        <App
          {...props}
          drawerState={{
            isDrawerOpen,
            handleToggleDrawer,
          }}
        />
      </DrawerLeftWrapper>
      <DrawerRightWrapper
        isDrawerOpen={isDrawerOpen}
        size={size}
        id="chatloading"
        style={{ overflowY: 'scroll' }}
      >
        <Box>
          <div style={{ marginTop: '55px' }}>
            {!props.match.path.includes('chat') && <News view={'drawer'} />}
          </div>
        </Box>
        {/* <ChatLoadIframe parentId={'chatloading'} iframeSizes={iframeSizes} /> */}
      </DrawerRightWrapper>
    </DrawerWrapper>
  );
};

const DrawerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

const DrawerLeftWrapper = styled.div`
  width: ${props => (props.isDrawerOpen ? props.size.left : '100%')};
`;

const DrawerRightWrapper = styled.div`
  width: ${props => (props.isDrawerOpen ? props.size.right : '0%')};
  visibility: ${props => (props.isDrawerOpen ? 'visible' : 'hidden')};
  height: 100%;
  overflow: hidden;
`;

export default Drawer;
