import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import { notifyMeNews, isMarketOpen } from '../../containers/helper';

let upArr = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="12px"
    height="12px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 15 15"
    xmlSpace="preserve"
  >
    <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
  </svg>
);

let downArr = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="12px"
    height="12px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 52 52"
    xmlSpace="preserve"
  >
    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
  </svg>
);
function getEmail() {
  let pl = localStorage.getItem('pl');
  if (pl) {
    return JSON.parse(pl).user_email;
  } else {
    return 'noemail@tradeui.com';
  }
}
const NewsTable = ({ searched, symbols, view, date, headlineOnly }) => {
  const wrkref = useRef();
  const tblRef = useRef();
  const [data, setData] = useState([]);
  const [nt, setNt] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [notifArr, setNotifArr] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  //  .format('MMMDD hh:mmA')
  const notifEnabled = JSON.parse(localStorage.getItem('newsNotif'));
  const notifSound = JSON.parse(localStorage.getItem('notifSound'));
  const idToken = localStorage.getItem('id_token');
  const userEmail = getEmail();
  const columns = useMemo(
    () => [
      {
        Header: () => 'Time',
        accessor: 'updated_at',
        width: 80,
        maxWidth: view === 'drawer' ? 70 : 100,
        Cell: e => {
          return (
            <span style={{ margin: 'auto', fontSize: '12px' }}>
              {moment(e.original.updated_at).format('hh:mm:ssA')}
            </span>
          );
        },
      },
      {
        Header: () => 'A.I Sentiment',
        accessor: 'sent',
        Cell: e => {
          let d = e.original;
          let color = '';
          if (d.pos) color = '#4dfa7b';
          if (d.neg) color = '#ff7070';
          if (d.neu) color = '#7251E6';
          return (
            <div
              style={{
                margin: 'auto',
                color: color,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                {/* {d.neu && (d.neu * 100).toFixed(2)}
                {d.pos && (d.pos * 100).toFixed(2)}
                {d.neg && (d.neg * 100).toFixed(2)}% */}
                <span
                  style={{
                    margin: 'auto 4px',
                    color: color,
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {d.neu && '-'}
                  {d.pos && upArr()}
                  {d.neg && downArr()}
                </span>
              </div>

              <span style={{ fontSize: '13px', color: 'inherit' }}>
                {d.neu && 'Neutral'}
                {d.pos && 'Bullish'}
                {d.neg && 'Bearish'}
              </span>
            </div>
          );
        },
        maxWidth: 150,
        width: 100,
        align: 'center',
        show:
          view === 'drawer'
            ? false
            : window.innerWidth < 800
            ? false
            : headlineOnly
            ? false
            : true,
      },
      {
        Header: () => 'Headlines',
        accessor: 'headline',
        //width: 800,
        minWidth: 200,
        Cell: e => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  margin: 'auto 7px',
                }}
              >
                {e.original.symbols
                  ? e.original.symbols.map((e, i) => (
                      <a href={'/stock/' + e} key={i}>
                        <span
                          style={{
                            padding: '1px 2px',
                            margin: '2px 2px',
                            fontSize: '13px',
                          }}
                          className={'HALT_RESUMES tickbx Option Exercise'}
                          // key={i}
                        >
                          {e}
                        </span>
                      </a>
                    ))
                  : null}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1
                  style={{
                    color: 'white',
                    marginLeft: '10px',
                    cursor: 'pointer',
                    fontSize:
                      view === 'drawer'
                        ? '14px'
                        : window.innerWidth < 800
                        ? '14px'
                        : 'unset',
                    flexWrap: 'wrap',
                  }}
                  onClick={() => window.open(e.original.url, '_blank')}
                >
                  {e.original.headline
                    .replace(new RegExp('&#39;', 'g'), "'")
                    .replace(new RegExp('&amp;', 'g'), '&')}
                </h1>
              </div>
            </div>
          );
        },
        style: { whiteSpace: 'normal' },
      },

      {
        Header: () => 'Author',
        accessor: 'author',
        Cell: e => (
          <div
            style={{
              margin: 'auto',
              textAlign: 'center',
              color: '#788195',
              fontSize: window.innerWidth < 800 ? '10px' : 'unset',
            }}
          >
            {e.original.author}
          </div>
        ),
        maxWidth: 100,
        show: view === 'drawer' ? false : headlineOnly ? false : true,
        style: { whiteSpace: 'normal' },
      },
      {
        Header: () => 'Source',
        accessor: 'source',
        Cell: e => (
          <div
            style={{ color: '#788195', margin: 'auto', textAlign: 'center' }}
          >
            {e.row.source.charAt(0).toUpperCase() + e.row.source.slice(1)}
          </div>
        ),
        maxWidth: 150,
        show:
          view === 'drawer'
            ? false
            : window.innerWidth < 800
            ? false
            : headlineOnly
            ? false
            : true,
      },
    ],
    [headlineOnly]
  );
  const setNotif = element => {
    if (notifEnabled === true) {
      let img, msg, symb;
      if (element.images) {
        if (element.images.length > 0) {
          img = element.images[2].url;
        }
      }
      msg = element.headline
        .replace(new RegExp('&#39;', 'g'), "'")
        .replace(new RegExp('&amp;', 'g'), '&');
      if (element.symbols) {
        if (element.symbols.length > 0) {
          symb = element.symbols.slice(0, 3).join(' ');
        } else {
          symb = '';
        }
      }
      setNotifArr(prev => [...prev, notifyMeNews(symb, msg, img)]);
      if (notifSound && isMarketOpen()) {
        let audio = new Audio('notif.mp3');
        audio.play();
      }
    }
  };
  const clearNotif = notArr => {
    for (let i = 0; i < notArr.length; i++) {
      notArr[i].close();
    }
  };
  const symbolMatch = (list1, list2) => {
    let matching = [];
    if (list2.length === 0) {
      return true;
    }
    if (list1.length > 0 && list2.length > 0) {
      for (let i = 0; i < list1.length; i++) {
        if (list2.includes(list1[i])) matching.push(list1[i]);
      }
    }
    if (matching.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    wrkref.current = new Worker('wrk.js');
    wrkref.current.postMessage({ token: idToken, email: userEmail });
    return () => {
      wrkref.current.terminate();
    };
  }, []);
  useEffect(() => {
    wrkref.current.postMessage({ type: 'dateSelectedNews', date: date });
  }, [date]);
  useEffect(() => {
    wrkref.current.onmessage = event => {
      if (event.data) {
        let msg = event.data;
        if (msg.type === 'newsDataInit') {
          if (moment(msg.date).format('YYYY-MM-DD') === date) {
            setData(msg.data);
            setNt(msg.nt);
            setTotalPages(msg.totalPages);
            setDataLoading(false);
          }
        }
        if (msg.type === 'newEntry') {
          let newEnt = msg.data;
          setData(prev => [newEnt, ...prev]);
          setNotif(newEnt);
        }
      }
    };
    if (!notifEnabled && notifArr.length > 0) {
      clearNotif(notifArr);
    }
  }, [notifEnabled, notifSound, notifArr, wrkref.current, date]);

  return (
    <div style={{ width: '100%' }}>
      <ReactTable
        ref={tblRef}
        className="rTable -striped -highlight Scanner Overview"
        columns={columns}
        data={data
          .filter(e => !e.null)
          .filter(
            e =>
              e.headline.toLowerCase().includes(searched) &&
              symbolMatch(e.symbols, symbols)
          )}
        noDataText={
          dataLoading ? 'Data loading...' : 'No data , try earlier dates above'
        }
        showPaginationTop={false}
        showPaginationBottom={true}
        style={{ width: '100%', minHeight: '200px' }}
        minRows={0}
        //pageSizeOptions={[10, 30, 50, 70, 100, 120, 150, 170, 200, 10250]}
        defaultPageSize={10}
        /*  SubComponent={e => {
          return (
            <div style={{ padding: '20px' }}>
              <div
                style={{ width: '80%', margin: '10px auto', color: 'white' }}
              >
                {e.original.summary}
              </div>
            </div>
          );
        }} */
        onPageChange={page => setPage(page)}
        /*  ExpanderComponent={e => {
          if (e !== undefined) {
            if (e.original.summary !== '' && e.original.summary !== '\n') {
              return (
                <span
                  style={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                    color: '#5099ff',
                    margin: '10px',
                  }}
                >
                  {e.isExpanded ? '-' : '+'}
                </span>
              );
            } else {
              return null;
            }
          }
        }} */
        collapseOnDataChange={true}
      />
    </div>
  );
};

export default NewsTable;
