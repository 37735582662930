//import fakeData from '../../customApp/containers/Watchlist/fakeData.js';
import AlertActions from './actions';

const colors = ['#7ED321', '#de1b1b', '#511E78', '#ff9009', '#42a5f5'];
//let todos = new fakeData(1, colors.length).getAll();

let todos = [];
let cats = { meow: 1 };
let testItem = { meow: 'wayli' };
//console.log(todos)
const initState = {
  testItem,
  todos,
  cats,
  colors,
};

export default function todoReducer(state = initState, action) {
  const todos = state.todos;
  const newtodos = [];
  switch (action.type) {
    case AlertActions.CHANGE_ALERT:
      return {
        ...state,
        todos: action.todos,
      };
    case AlertActions.ADD_ALERT:
      return {
        ...state,
        todos: [action.todos, ...state.todos],
      };
    case AlertActions.ALL_COMPLETED:
      todos.forEach(todo => {
        todo.completed = true;
        newtodos.push(todo);
      });
      return {
        ...state,
        todos: newtodos,
      };
    case AlertActions.DELETE_COMPLETED:
      todos.forEach(todo => {
        if (todo.completed !== true) {
          newtodos.push(todo);
        }
      });
      return {
        ...state,
        todos: newtodos,
      };
    default:
      return state;
  }
}
