const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
  margin: '5px auto',
};
const colStyle = {
  marginBottom: '3px',
  padding: '3px',
};
const colStyleNobt = {
  marginBottom: '3px',
  padding: '5px',
};
const colStyleNotPad = {
  marginBottom: '6px',
  padding: '0px',
};
const alertStyle2 = {
  border: '5px solid black;',
};
const widgetStyle = {};
const alertStyle = {
  margin: '5px',
  // width: '90%',
  display: 'flex',
  flexFlow: 'row wrap',
};
const longp = {
  color: '#34c38f!important',
};
const shortp = {
  color: ' #ff2f64 !important;',
};
const signalChart = {
  padding: '0px!important',
};
const gutter = 16;
const basicStyle = {
  shortp,
  longp,
  alertStyle,
  widgetStyle,
  rowStyle,
  colStyle,
  gutter,
  alertStyle2,
  colStyleNotPad,
  colStyleNobt,
};

export default basicStyle;
