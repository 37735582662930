import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, ConfigProvider, Drawer } from 'antd';
import { IntlProvider } from 'react-intl';
import message from '@isomorphic/shared/isomorphic/components/Feedback/Message';
import MessageContent from '../../containers/Feedback/Message/message.style';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import SocialWNew from '../Topbar/SocialWNew';
import ChatBox from '../../customApp/components/ChatBox2';
import ThemeSwitcher from '../../containers/ThemeSwitcher';
import AppRouter from './AppRouter';
import { iWS } from './ws';
import { siteConfig } from '../../settings';
import { AppLocale } from '../../dashApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import ApiDownNotice from '../Notice/apiDownNotice';
import { getLists } from '../../redux/watchlist/actions';
import SocketConnected from '../Topbar/Clock_widget/SocketConnected'; 
import { store } from '../../redux/store';
import styled from 'styled-components';
import { setGap } from '../../redux/worthWatching/actions';
import './global.css';


// import {

// } from '../../redux/signals/actions';
// import SocialW from '../Topbar/SocialW';
// import { IWSTop } from '../../customApp/containers/helper';
// import { Debounce } from 'react-throttle';
// import WindowResizeListener from 'react-window-size-listener';
//import { Layout, LocaleProvider } from 'antd';
// const REACT_VERSION = React.version;
// console.log('REACT_VERSION', REACT_VERSION);
console.error = message => {
  if (message.includes('ReactDOM.render is no longer supported in React 18')) {
    return;
  }
};

const CloseIcon = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="close"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
  </svg>
);
const ResponsiveDrawer = styled(Drawer)`
  @media (max-width: 768px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;
const { Content, Footer } = Layout;
const { logout } = authAction;
const {
  toggleAll,
  setIsRole0,
  setIsRole1,
  setIsRole2,
  setIsRole3,
} = appActions;

const pagesTitles = {
  darkpools: 'Dark Pools',
  welcome: 'Welcome',
  dashboard: 'Dashboard',
  flow: 'Option Flow',
  news: 'News',
  alerts: 'Alerts',
  watchlists: 'Watchlists',
  overview: 'Market Overview',
  earnings: 'Earnings Calendar',
  strategies: 'AI Strategies',
  options: 'Option Screener',
  exposure: 'Gamma Exposure Levels',
  chat: 'Chat - AI Financial Assistant',
  insights: 'Option Insights',
  chain: 'Chain Viewer',
  chain_new: 'Chain Viewer',
  ticker: 'Ticker View',
  stock: 'Stock Summary',
  chart: 'Automated Charts',
  aifeed: 'AI Feed - Entry Exit Signals',
  upgrade: 'TradeUI Pro, Unlock Trading Greatness',
  signals: 'Stock Signals',
  'chart-setups': 'Chart Setups',
  moneyline: 'Moneyline',
  trade: 'Trade',
  zdte: 'Zdte',
  insiders: 'Insiders Filings',
  analysts: 'Analyst Stock Ratings',
  aggreflow: 'AggreFlow',
  backtester: 'Backtester',
  smartentry: 'SmartEntry',
  radar: 'Radar',
  deepstrike: 'DeepStrike',
  optioncalculator: 'Option Calculator',
  riskreward: 'Risk Reward',
  guide: 'Strategies Guide',
  alerts: 'Smart Alerts',
};

const uid = localStorage.getItem('pl')
  ? JSON.parse(localStorage.getItem('pl')).uid
  : null;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export class App extends Component {
  constructor() {
    super();
    this.state = {
      socket: 'red',
      appHeight: window.innerHeight,
      role: 'anime',
      drawerVisibleSocial: false,
      socialData: {
        st: [],
        wsb: [],
        sizz: [],
        pmar: {},
        gapp: [],
      },
    };
  }
  layoutRef = React.createRef();

  updateSocialData = data => {
    this.setState({ socialData: data });
    store.dispatch(setGap(data.gapp));
  };
  checkForRoles = () => {
    const pl = JSON.parse(localStorage.getItem('pl'));
    if (pl && Array.isArray(pl.roles)) {
      const roles = pl.roles;
      let roleMatchFound = false;
      for (const role of roles) {
        if (role.startsWith('pmpro_role') || role === 'tradeuipro') {
          roleMatchFound = true;
          break;
        }
      }
      if (roleMatchFound) {
        this.props.setIsRole0(true);
        this.props.setIsRole1(true);
        this.props.setIsRole2(true);
        this.props.setIsRole3(true);
      }
    } else {
      // setTimeout(this.checkForRoles, 500);
    }
  };
  toggleDrawerSocial = () => {
    this.setState(prevState => ({
      drawerVisibleSocial: !prevState.drawerVisibleSocial,
    }));
  };
  toggleDrawerChat = () => {
    this.setState(prevState => ({
      drawerVisibleChat: !prevState.drawerVisibleChat,
    }));
  };
  componentDidMount() {
    //document.title = `${pagesTitles[this.props.currentPage]} • TradeUI`;
    document.title =
      pagesTitles[this.props.currentPage] !== undefined
        ? `${pagesTitles[this.props.currentPage]} • TradeUI`
        : 'Loading • TradeUI';

    if (localStorage.getItem('id_token')) {
      const idt = localStorage.getItem('id_token');
      //iWS(idt);
      iWS(idt, this.updateSocialData);
    } else {
      message.error({
        content: <MessageContent>{'Please Login'}</MessageContent>,
        key: 'msg',
        duration: 0,
      });
      window.location.href = '/signin';
    }
    //IWSTop();
    //this.liveTickers();
    this.checkForRoles();
    this.props.getLists(uid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentPage !== this.props.currentPage) {
      document.title = `${pagesTitles[this.props.currentPage]} • TradeUI`;
    }
  }
  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme } = this.props;
    const currentAppLocale = AppLocale[locale];
    //const appHeight = window.innerHeight;

    let SidebarEl = <Sidebar url={url} cats={true} />;
    let topbar = (
      <Topbar
        url={url}
        drawerState={this.props.drawerState}
        toggleDrawerSocial={this.toggleDrawerSocial}
        drawerVisibleSocial={this.state.drawerVisibleSocial}
        toggleDrawerChat={this.toggleDrawerChat}
      />
    );

    ///const { isRole3, isRole0, isRole1, isRole2 } = this.props;

    if (window.location.search.includes('nosb')) {
      SidebarEl = '';
    }

    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: '100vh' }} id="mainLayout">
                {/* <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize => {
                      if (this.props.drawerState.isDrawerOpen === false) {
                        this.props.toggleAll(
                          windowSize.windowWidth,
                          windowSize.windowHeight
                        );
                      }
                    }}
                  />
                </Debounce> */}
                <ApiDownNotice />
                {topbar}

                <Layout
                  style={{
                    flexDirection: 'row',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                  }}
                >
                  {SidebarEl}
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: '100vh',
                    }}
                  >
                    <Drawer
                      title={
                        <span>
                          <i
                            style={{
                              color: 'rgb(114 81 230)',
                              fontSize: '17px',
                              verticalAlign: 'middle',
                            }}
                            className="ion-bonfire changelogx"
                          ></i>{' '}
                          Worth Watching
                        </span>
                      }
                      placement="top"
                      closable={true}
                      onClose={this.toggleDrawerSocial}
                      visible={this.state.drawerVisibleSocial}
                      height={'37%'}
                    >
                      <SocialWNew
                        socialData={this.state.socialData}
                        updateSocialData={this.updateSocialData}
                        socket={this.state.socket}
                      />
                    </Drawer>
                    <ResponsiveDrawer
                      title={
                        <DrawerHeader>
                          <span>
                            <i
                              style={{
                                color: 'rgb(114 81 230)',
                                fontSize: '20px',
                                verticalAlign: 'middle',
                              }}
                              className="ion-chatbox-working"
                            ></i>{' '}
                            AI Chat
                          </span>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={this.toggleDrawerChat}
                            type="text"
                          >
                            <CloseIcon />
                          </span>
                        </DrawerHeader>
                      }
                      placement="right"
                      closable={false}
                      onClose={this.toggleDrawerChat}
                      visible={this.state.drawerVisibleChat}
                      width={'50%'}
                    >
                      <ChatBox isOpen={this.state.drawerVisibleChat} />
                    </ResponsiveDrawer>
                    <Content
                      id="TUIc"
                      className="isomorphicContent"
                      style={{
                        marginTop: '45px',
                        padding: '40px 0 0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative',
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>

                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid transparent',
                        color: '#6770ab',
                      }}
                    >
                      <a
                        href="https://tradeui.com"
                        target="_blank"
                        style={{ color: '#6770ab' }}
                      >
                        <SocketConnected /> TradeUI
                        {/* <i className="ion ion-flash hrtanim"></i> */}
                      </a>{' '}
                      <br />
                      <a
                        href="https://tradeui.com/pricing"
                        style={{ color: '#6770ab' }}
                        target="_blank"
                      >
                        Pricing
                      </a>{' '}
                      |
                      <a
                        href="https://tradeui.com/guest"
                        target="_blank"
                        style={{ color: '#6770ab' }}
                      >
                        {' '}
                        Join Community
                      </a>{' '}
                      |
                      <a
                        target="_blank"
                        href="https://tradeui.com/disclaimer"
                        style={{ color: '#6770ab' }}
                      >
                        {' '}
                        Legal Disclaimer
                      </a>
                      <br />
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
                <ThemeSwitcher />
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect(
  state => {
    return {
      ab: 'test123',
      auth: state.Auth,
      locale: state.LanguageSwitcher.language.locale,
      selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
      height: state.App.height,
      isRole3: state.App.isRole3,
      isRole0: state.App.isRole0,
      isRole1: state.App.isRole1,
      isRole2: state.App.isRole2,
      currentPage: state.App.current[0],
    };
  },
  {
    logout,
    toggleAll,
    setIsRole0,
    setIsRole1,
    setIsRole2,
    setIsRole3,
    getLists,
  }
)(App);
