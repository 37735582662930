import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MarketPosition = () => {
  const [lastPosition, setLastPosition] = useState(null);

  useEffect(() => {
    const fetchMarketPosition = async () => {
      try {
        const response = await axios.get('https://ab.tradeui.com/exp/market_data.json');
        const marketPositions = response.data.market_position;
        if (marketPositions && marketPositions.length > 0) {
          const lastPositionData = marketPositions[marketPositions.length - 1];
          setLastPosition(lastPositionData[1]);  
        }
      } catch (error) {
        console.error('Error fetching market position:', error);
      }
    };

    fetchMarketPosition();
    // Fetch data every 3HOURS
    const intervalId = setInterval(fetchMarketPosition, 180 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const getBackgroundColor = (value) => {
 
    const colorStops = [
      { value: -3, color: '#ff2f64' },   // Bearish
      { value: -2, color: '#ff2f64' },   // Bearish
      { value: -1, color: '#ff2f64' },   // Bearish
      { value: 0, color: '#7252e6' },    // Neutral
      { value: 1, color: '#00b399' },    // Bullish
      { value: 2, color: '#00b399' },    // Bullish
      { value: 3, color: '#00b399' }     // Bullish
    ];

    // Find the exact match or the closest lower value
    const matchedStop = colorStops.reduce((prev, curr) => 
      (curr.value <= value && curr.value > prev.value) ? curr : prev
    );

    return matchedStop.color;
  };



  if (lastPosition === null) {
    return <span>Loading market position...</span>;
  }

  return (
    <span className="market-position"> 
     <span className="mpbadge"
        style={{
            backgroundColor: getBackgroundColor(lastPosition),
            color: 'white',
            padding: '1px 4px',
            borderRadius: '1px',
            fontWeight: 'bold'
          }} >{lastPosition.toLocaleString()}</span>
    </span>
  );
};

export default MarketPosition;