const initState = {
  aiSignals: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_AI_SIGNALS':
      return {
        ...state,
        aiSignals: action.payload,
      };
    case 'ADD_AI_SIGNAL':
      return {
        ...state,
        aiSignals: [...state.aiSignals, action.payload],
      };

    default:
      return state;
  }
};

export default reducer;
