const DRAWER_STATE_PERSIST_KEY = 'DRAWER_STATE_XYZ';
export const GetDrawerState = () => {
  if (window) {
    const drawerState = window.localStorage.getItem(DRAWER_STATE_PERSIST_KEY);
    return JSON.parse(drawerState) ?? false;
  }
};

export const SetDrawerState = state => {
  if (window) {
    window.localStorage.setItem(
      DRAWER_STATE_PERSIST_KEY,
      JSON.stringify(state)
    );
  }
};
