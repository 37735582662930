import React from 'react';
import styled from 'styled-components';
import MarketOpenCloseStatus from './MarketOpenCloseStatus';
// import SocketConnected from './SocketConnected';

const ClockWidget = () => {
  return (
    <Wrapper>
      <MarketOpenCloseStatus />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
`;
export default ClockWidget;
