import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const ChatBox = ({ isOpen }) => {
  const welcomeMessage = {
    text:
      "Welcome to the experimental AI chat for trading and finance! I'm here to assist you with any trading or finance-related questions. Please note that I don't have access to real-time data or personal information, but I can provide general knowledge and insights based on my training. How can I help you today?",
    sender: 'bot',
    isHtml: false,
  };
  const [input, setInput] = useState('');
  const [typingMessage, setTypingMessage] = useState('');
  const chatContainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const inputRef = useRef(null);
  const [messages, setMessages] = useState([welcomeMessage]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleInputChange = event => {
    setInput(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && input.trim() !== '') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const fetchBotResponse = async userMessage => {
    try {
      const response = await axios.get(
        `http://ab.tradeui.com:4000/api/fin?question=${encodeURIComponent(
          userMessage
        )}`
      );
      return response.data.response;
    } catch (error) {
      console.error('Error fetching bot response:', error);
      return 'Sorry, I encountered an error while processing your request.';
    }
  };

  const simulateBotResponse = async userMessage => {
    const botResponse = await fetchBotResponse(userMessage);
    let typingIndex = 0;
    setTypingMessage('');
    const typingInterval = setInterval(() => {
      if (typingIndex <= botResponse.length) {
        setTypingMessage(botResponse.slice(0, typingIndex));
        typingIndex++;
      } else {
        clearInterval(typingInterval);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: botResponse, sender: 'bot', isHtml: true },
        ]);
        setTypingMessage('');
      }
    }, 1);
  };

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    // Focus the input field when the ChatBox becomes visible
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleSendMessage = () => {
    if (input.trim() === '') return;
    const userMessage = input.trim();
    setMessages([
      ...messages,
      { text: userMessage, sender: 'user', isHtml: false },
    ]);
    setInput('');
    simulateBotResponse(userMessage);
  };
  const renderMessage = message => {
    if (message.isHtml) {
      return <div dangerouslySetInnerHTML={{ __html: message.text }} />;
    }
    return message.text;
  };

  return (
    <div
      style={{
        position: isFullScreen ? 'fixed' : 'relative',
        top: isFullScreen ? '0' : 'auto',
        left: isFullScreen ? '0' : 'auto',
        right: isFullScreen ? '0' : 'auto',
        bottom: isFullScreen ? '0' : 'auto',
        width: '100%',
        height: isFullScreen ? '100%' : '90vh',
        zIndex: isFullScreen ? 9999 : 1000,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        backgroundColor: isFullScreen ? 'white' : 'transparent',
      }}
    >
      <div
        ref={chatContainerRef}
        style={{
          flex: '1',
          overflowY: 'auto',
          padding: '10px',
          lineHeight: '1.6',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              textAlign: message.sender === 'bot' ? 'left' : 'right',
              marginBottom: '10px',
            }}
          >
            <span
              style={{
                background:
                  message.sender === 'bot' ? '#483e6b99' : 'rgb(121 129 149)',
                padding: '10px',
                borderRadius: '5px',
                display: 'inline-block',
                maxWidth: '80%',
                wordWrap: 'break-word',
              }}
            >
              {renderMessage(message)}

              {/* {message.text} */}
            </span>
          </div>
        ))}
        <div style={{ color: '#aaa', fontStyle: 'italic' }}>
          <i>{typingMessage}</i>
        </div>
      </div>
      <div
        style={{
          padding: '10px',
          background: '#1e1e2d',
        }}
      >
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            width: '100%',
            marginBottom: '10px',
            padding: '10px',
            borderRadius: '5px',
            border: '0px',
            color: 'black',
            fontWeight: 'bold',
          }}
        />
        <button
          onClick={handleSendMessage}
          style={{
            width: '100%',
            padding: '10px 20px',
            background: 'rgb(114 81 230 / 60%)',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          SEND
        </button>
      </div>
      <button
        onClick={toggleFullScreen}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: 0,
        }}
      >
        {isFullScreen ? (
          <svg
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3v3a2 2 0 01-2 2H3m18 0h-3a2 2 0 01-2-2V3m0 18v-3a2 2 0 012-2h3M3 16h3a2 2 0 012 2v3"
              stroke={isFullScreen ? 'black' : 'white'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3H5a2 2 0 00-2 2v3m18 0V5a2 2 0 00-2-2h-3m0 18h3a2 2 0 002-2v-3M3 16v3a2 2 0 002 2h3"
              stroke={isFullScreen ? 'black' : 'white'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default ChatBox;
