import React from 'react';

const UpArrow = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="12px"
    height="12px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 15 15"
    xmlSpace="preserve"
  >
    <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
  </svg>
);

export default UpArrow;
