import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import axios from 'axios';

const getLists = async uid => {
  try {
    const { data } = await axios.get(
      `https://ab.tradeui.com/v1/watchlists/${uid}`
    );
    return data;
  } catch (error) {}
};
const getStockByList = async watchList => {
  try {
    const { data } = await axios.get(
      `https://ab.tradeui.com/v1/watchlist/${watchList.id}/stocks`
    );

    if (data.length > 0) {
      return data;
    }
  } catch (error) {}
};
export function* getWatchlists() {
  yield takeEvery('GET_LISTS', function*({ payload }) {
    const uid = payload;
    const listsData = yield call(getLists, uid);
    if (Array.isArray(listsData) && listsData.length > 0) {
      yield put({ type: 'SET_LISTS', payload: listsData });
      yield put({ type: 'SET_SELECTED_LIST', payload: listsData[0] });
    }
  });
}

export function* getStocks() {
  yield takeEvery('SET_SELECTED_LIST', function*({ payload }) {
    const list = payload;
    const stocks = yield call(getStockByList, list);
    if (Array.isArray(stocks) && stocks.length > 0) {
      yield put({ type: 'SET_WATCH_DATA', payload: stocks });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getWatchlists), fork(getStocks)]);
}
