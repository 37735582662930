import Auth from './auth/reducer';
import App from './app/reducer';
import Box from './box/reducer';
import Alerts from './todos/reducer';
import Signals from './signals/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
//import DevReducers from '../customApp/redux/reducers';
// import Calendar from './calendar/reducer';
// import Cards from './card/reducer';
// import Chat from './chat/reducers';
import drawer from './drawer/reducer';
import modal from './modal/reducer';
import flow from './flow/reducer';
import Technicals from './technicals/reducer';
import notifications from './notifications/reducer';
import worthWatching from './worthWatching/reducer';
import leaders from './leaders/reducer';
import watchlist from './watchlist/reducer';
//import YoutubeSearch from './youtubeSearch/reducers';
// import Mails from './mail/reducer';
// import Notes from './notes/reducer';
//import Contacts from './contacts/reducer';
//import DynamicChartComponent from './dynamicEchart/reducer';
//import Articles from './articles/reducers';
// import Ecommerce from './ecommerce/reducer';
// import Invoices from './invoice/reducer';
// import Investors from './investors/reducers';
// import scrumBoard from './scrumBoard/reducer';
export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Box,
  Signals,
  Alerts,
  modal,
  drawer,
  flow,
  Technicals,
  notifications,
  worthWatching,
  leaders,
  watchlist,
  // Mails,
  // Notes,
  // Contacts,
  // Cards,
  // Chat,
  // Calendar,
  // DynamicChartComponent,
  // Ecommerce,
  // Invoices,
  // YoutubeSearch,
  // Articles,
  // Investors,
  // scrumBoard,
  // ...DevReducers,
};
