import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';

//import { setToken, clearToken, getToken } from '../../helpers/utility';

import { clearToken, getToken } from '../../helpers/utility';

import AuthHelper from '../../helpers/authHelper';
import notification from '@isomorphic/shared/isomorphic/components/Notification';

//const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { history, userInfo } = payload.token;
    const result = yield call(AuthHelper.login, userInfo);
    if (result.token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: result,
        token: result.token,
        history,
      });
    } else {
      notification('error', result.error || result);
      yield put({ type: actions.LOGIN_ERROR, payload: true });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*({ payload, history }) {
    yield localStorage.setItem('id_token', payload.token);
    var date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    let cookiepl = `cache=${payload.token}:${
      payload.user_email
    }; expires=${date.toGMTString()}; path=/;`;
    document.cookie = cookiepl;

    localStorage.setItem('pl', JSON.stringify(payload));
    //yield setToken(payload.token);
    if (history) {
      history.push('/flow');
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const { token } = AuthHelper.checkExpirity(getToken());
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { token },
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
