const initState = {
  lists: [],
  watchData: [],
  selectedList: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LISTS':
      return {
        ...state,
        lists: action.payload,
      };
    case 'GET_LISTS':
      return state;
    case 'SET_SELECTED_LIST':
      return {
        ...state,
        selectedList: action.payload,
      };
    case 'SET_WATCH_DATA':
      return {
        ...state,
        watchData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
