import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { siteConfig } from '../../../Library';
import { useHistory } from 'react-router-dom';

let timeto = {
  fontWeight: '600',
  fontFamily: 'inherit',
  float: 'right',
  backgroundColor: '#050f14',
  fontSize: '10px',
  color: 'rgb(80, 153, 255)',
};
let updateB = '';

const Logo = ({ collapsed, toggleCollapsed }) => {
  const history = useHistory();

  const handleImageClick = event => {
    event.preventDefault();
    history.push('/flow');
  };
  return (
    <div className="isoLogoWrapper" style={{ marginTop: '10px' }}>
      {collapsed ? (
        <div className="ctrme">
          <h3>
            <div
              onClick={() => toggleCollapsed()}
              style={{
                margin: 'auto',
              }}
            >
              <img
                style={{ cursor: 'pointer', maxWidth: '45px' }}
                onClick={handleImageClick}
                src="/tui.png"
              />
              {/* {updateB} */}
            </div>
          </h3>
          {/* <Link to="/my/overview"> */}
        </div>
      ) : (
        <h3>
          {/* <Link to="/my/overview"> */}
          <div>
            <img
              style={{ cursor: 'pointer', maxWidth: '45px' }}
              onClick={handleImageClick}
              src="/tui.png"
            />
            {/* <span title='Local Time' id='timeto'><span  style={timeto}> {date.toLocaleTimeString('en-GB')} </span></span> */}
            <div onClick={() => toggleCollapsed()}>{updateB}</div>
          </div>
        </h3>
      )}
    </div>
  );
};

export default Logo;
