import {
  SET_CHART_DATA,
  SET_CHART_DATA_LOADING,
  SET_CHART_TICKER,
  SET_SIGNAL_TABLE_DATA,
  SET_SIGNAL_TABLE_DATA_DATE,
  SET_SIGNAL_TABLE_DATA_ERROR,
  SET_SIGNAL_TABLE_DATA_LOADING,
  SET_SGNB_DATA,
  SET_TIME_INTERVAL,
  SET_VIEW_STYLE,
  SET_SIGNAL_TYPE,
} from './actions';

const initialState = {
  signalTableData: null,
  signalTableDataLoading: true,
  signalTableDataDate: null,
  signalTableDataError: 'No date selected',
  sgnbData: [],
  chartTicker: null,
  chartDataLoading: true,
  chartData: null,
  chartLineData: null,
  timeInterval: '',
  viewStyle: 'column',
  signalType: 'all',
  signalData: null,
  priceFrom: null,
  priceTo: null,
};

export default function signalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIGNAL_TABLE_DATA:
      return { ...state, signalTableData: action.payload };
    case SET_SIGNAL_TABLE_DATA_LOADING:
      return { ...state, signalTableDataLoading: action.payload };
    case SET_SIGNAL_TABLE_DATA_DATE:
      return { ...state, signalTableDataDate: action.payload };
    case SET_SIGNAL_TABLE_DATA_ERROR:
      return { ...state, signalTableDataError: action.payload };
    case SET_SGNB_DATA:
      return { ...state, sgnbData: action.payload };
    case SET_CHART_TICKER:
      return { ...state, chartTicker: action.payload };
    case SET_CHART_DATA_LOADING:
      return { ...state, chartDataLoading: action.payload };
    case SET_CHART_DATA:
      return { ...state, chartData: action.payload };
    case SET_TIME_INTERVAL:
      return { ...state, timeInterval: action.payload };
    case SET_VIEW_STYLE:
      return { ...state, viewStyle: action.payload };
    case SET_SIGNAL_TYPE:
      return { ...state, signalType: action.payload };
    case 'SET_SIGNAL_DATA':
      return { ...state, signalData: action.payload };
    case 'SET_CHART_LINE_DATA':
      return { ...state, chartLineData: action.payload };
    case 'SET_PRICE_FROM':
      return { ...state, priceFrom: action.payload };
    case 'SET_PRICE_TO':
      return { ...state, priceTo: action.payload };
    default:
      return state;
  }
}
