/**
 * Signal Table related stuffs
 **/

//Types
export const SET_SIGNAL_TABLE_DATA = 'SET_SIGNAL_TABLE_DATA';
export const SET_SIGNAL_TABLE_DATA_LOADING = 'SET_SIGNAL_TABLE_DATA_LOADING';
export const SET_SIGNAL_TABLE_DATA_DATE = 'SET_SIGNAL_TABLE_DATA_DATE';
export const SET_SIGNAL_TABLE_DATA_ERROR = 'SET_SIGNAL_TABLE_DATA_ERROR';

//Actions
export const setSignalTableData = data => ({
  type: SET_SIGNAL_TABLE_DATA,
  payload: data,
});
export const setSignalTableDataLoading = state => ({
  type: SET_SIGNAL_TABLE_DATA_LOADING,
  payload: state,
});
export const setSignalTableDataDate = date => ({
  type: SET_SIGNAL_TABLE_DATA_DATE,
  payload: date,
});

export const setSignalTableDataError = error => ({
  type: SET_SIGNAL_TABLE_DATA_ERROR,
  payload: error,
});

//Saga types
export const SET_SIGNAL_TABLE_DATA_SAGA = 'SET_SIGNAL_TABLE_DATA_SAGA';
//Saga actions

export const setSignalTableDataSaga = payload => ({
  type: SET_SIGNAL_TABLE_DATA_SAGA,
  payload,
});

//Chart ticker

export const SET_CHART_TICKER = 'SET_CHART_TICKER';

export const setChartTicker = ticker => ({
  type: SET_CHART_TICKER,
  payload: ticker,
});

export const SET_CHART_DATA_LOADING = 'SET_CHART_DATA_LOADING';
export const setChartDataLoading = state => {
  return {
    type: SET_CHART_DATA_LOADING,
    payload: state,
  };
};

export const SET_CHART_DATA = 'SET_CHART_DATA';
export const setChartData = chartdata => ({
  type: SET_CHART_DATA,
  payload: chartdata,
});

export const SET_TIME_INTERVAL = 'SET_TIME_INTERVAL';
export const setTimeInterval = timeInterval => ({
  type: SET_TIME_INTERVAL,
  payload: timeInterval,
});

//Sgnb Data
export const SET_SGNB_DATA = 'SET_SGNB_DATA';
export const setSgnbData = sgnbData => ({
  type: SET_SGNB_DATA,
  payload: sgnbData,
});

//view Style
export const SET_VIEW_STYLE = 'SET_VIEW_STYLE';
export const setViewStyle = viewStyle => ({
  type: SET_VIEW_STYLE,
  payload: viewStyle,
});

export const SET_SIGNAL_TYPE = 'SET_SIGNAL_TYPE';
export const setSignalType = signalType => ({
  type: SET_SIGNAL_TYPE,
  payload: signalType,
});
export const setSignalData = signalData => ({
  type: 'SET_SIGNAL_DATA',
  payload: signalData,
});
export const setChartLineData = lineData => ({
  type: 'SET_CHART_LINE_DATA',
  payload: lineData,
});
export const setPriceFrom = priceFrom => ({
  type: 'SET_PRICE_FROM',
  payload: priceFrom,
});
export const setPriceTo = priceTo => ({
  type: 'SET_PRICE_TO',
  payload: priceTo,
});
