// Popout.js
import React from 'react';
class Popout extends React.Component {
  openPopout = () => {
    const {
      link,
      width = 800,
      height = 600,
      top = 200,
      left = 500,
    } = this.props;
    const features = `width=${width},height=${height},top=${top},left=${left},location=0,status=0,menubar=0`;
    window.open('/' + link + '?nosb=1', 'popup', features);
  };

  render() {
    return (
      <button
        onClick={this.openPopout}
        style={{
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          float: 'right',
          position: 'relative',
          top: '5px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 24 24"
        >
          <title id="title">Pop out watchlists widget</title>
          <path
            fill="#7251e6"
            d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
          ></path>
        </svg>
      </button>
    );
  }
}

export default Popout;
