const initState = {
  misData: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_MIS_DATA':
      return {
        ...state,
        misData: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
