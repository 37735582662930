import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import devSagas from '../customApp/redux/sagas';
import watchlistSagas from './watchlist/saga';
// import todosSagas from './todos/saga';
// import cardsSagas from './card/saga';
// import chatSagas from './chat/sagas';
//import signalSagas from './signals/saga';
//import contactSagas from './contacts/saga';
// import invoicesSagas from './invoice/saga';
// import articles from './articles/sagas';
// import investors from './investors/sagas';
// import scrumBoardSaga from './scrumBoard/saga';
// import mailSagas from './mail/saga';
// import notesSagas from './notes/saga';
// import ecommerceSaga from './ecommerce/saga';
// import youtubeSearchSagas from './youtubeSearch/sagas';
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    // todosSagas(),
    // cardsSagas(),
    // chatSagas(),
    //signalSagas(),
    devSagas(),
    watchlistSagas(),
    // contactSagas(),
    // mailSagas(),
    // notesSagas(),
    // ecommerceSaga(),
    // invoicesSagas(),
    // youtubeSearchSagas(),
    // articles(),
    // investors(),
    // scrumBoardSaga(),
  ]);
}
