async function editRequestToDB(todo) {
  try {
    let note = todo.note;
    let id = todo.id;

    let maxAlerts = false;
    if (todo.max != '0') {
      maxAlerts = 1;
    }

    if (window.ccn && window.ccn.readyState == 1) {
      let payload = {};
      payload.type = 'CAE';
      payload.notes = note;
      payload.id = id;
      payload.uid = window.uid;
      payload.color = todo.color;
      payload.maxAlerts = maxAlerts;

      window.ccn.send(JSON.stringify(payload));
    }
    // if active is false then set the max to 0
  } catch (error) {
    console.error(error);
  }
}
async function deleteRequestToDB(id) {
  try {
    if (window.ccn && window.ccn.readyState == 1) {
      let payload = {};
      payload.type = 'CAD';
      payload.uid = window.uid;
      payload.id = id;
      window.ccn.send(JSON.stringify(payload));
    }
  } catch (error) {
    console.error(error);
  }
}

//
const todoActions = {
  CHANGE_ALERT: 'CHANGE_ALERT',
  ALL_COMPLETED: 'ALL_COMPLETED',
  ADD_ALERT: 'ADD_ALERT',
  allAlerts: () => {
    return (dispatch, getState) => {
      const oldTodos = getState().Alerts.todos;
      const todos = [];
      oldTodos.forEach(todo => {
        todo.activated = true;
        todos.push(todo);
      });
      dispatch({
        type: todoActions.CHANGE_ALERT,
        todos,
      });
    };
  },
  addAlert: (ticker, todo, alertid, color, max, createtime) => {
    return (dispatch, getState) => {
      if (alertid == null) {
        alertid = new Date();
      }

      let ladate = new Date(parseFloat(createtime) * 1000);

      const newTodo = {
        id: alertid,
        ticker: ticker,
        note: todo,
        createTime: ladate,
        color: color,
        max: max,
      };
      const todos = [newTodo, ...getState().Alerts.todos];
      dispatch({
        type: todoActions.ADD_ALERT,
        todos: newTodo,
      });
    };
  },

  //diali
  deleteAlert: id => {
    return (dispatch, getState) => {
      const oldTodos = getState().Alerts.todos;
      if (oldTodos && Object.keys(oldTodos).length > 0) {
        const todos = [];
        oldTodos.forEach(todo => {
          if (todo.id !== id) {
            todos.push(todo);
          } else {
            deleteRequestToDB(todo.id);
          }
        });
        dispatch({
          type: todoActions.CHANGE_ALERT,
          todos,
        });
      } else {
        alert('no alerts');
      }
    };
  },

  clearAlerts: () => {
    return (dispatch, getState) => {
      const todos = [];

      dispatch({
        type: todoActions.CHANGE_ALERT,
        todos,
      });
    };
  },
  edittodo: editTodo => {
    return (dispatch, getState) => {
      const oldTodos = getState().Alerts.todos;

      const todos = [];
      oldTodos.forEach(todo => {
        if (todo.id !== editTodo.id) {
          todos.push(todo);
        } else {
          if (editTodo.activated == false) editTodo.max = 0;
          if (editTodo.activated == true && editTodo.max == '0')
            editTodo.max = 1;
          todos.push(editTodo);

          editRequestToDB(todo);
        }
      });
      dispatch({
        type: todoActions.CHANGE_ALERT,
        todos,
      });
    };
  },

  allCompleted: () => {
    try {
      if (window.ccn && window.ccn.readyState == 1) {
        let payload = {};
        payload.type = 'AAA';
        window.ccn.send(JSON.stringify(payload));
      }
    } catch (error) {
      console.error(error);
    }
    return (dispatch, getState) => {
      const oldTodos = getState().Alerts.todos;
      const todos = [];
      oldTodos.forEach(todo => {
        todo.activated = true;

        if (todo.max == '0') todo.max = 1;
        todos.push(todo);
      });
      dispatch({
        type: todoActions.CHANGE_ALERT,
        todos,
      });
    };
  },
  deleteCompleted: () => {
    try {
      if (window.ccn && window.ccn.readyState == 1) {
        let payload = {};
        payload.type = 'DAA';
        window.ccn.send(JSON.stringify(payload));
      }
    } catch (error) {
      console.error(error);
    }
    return (dispatch, getState) => {
      const oldTodos = getState().Alerts.todos;
      const todos = [];
      oldTodos.forEach(todo => {
        todo.activated = false;
        if (todo.max != '0' && todo.activated == false) todo.max = 0;
        //if (!todo.completed) {
        todos.push(todo);
        //}
      });
      dispatch({
        type: todoActions.CHANGE_ALERT,
        todos,
      });
    };
  },
};
export default todoActions;
