import React from 'react';

const DownArrow = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="12px"
    height="12px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 52 52"
    xmlSpace="preserve"
  >
    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
  </svg>
);

export default DownArrow;
