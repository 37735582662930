import React, { useState, useEffect } from 'react';
//import LayoutContentWrapper from '@isomorphic/shared/isomorphic/components/utility/layoutWrapper';
import { Row, Col } from 'antd';
import PageHeader from '@isomorphic/shared/isomorphic/components/utility/pageHeader';
import NewsTable from '../components/news/NewsTable';
import CreatableSelect from 'react-select/creatable';
import basicStyle from '../../settings/basicStyle';
import Checkbox from '@isomorphic/shared/isomorphic/components/uielements/checkbox';
import Tooltip from '@isomorphic/shared/isomorphic/components/uielements/tooltip';
import moment from 'moment';
import 'react-table/react-table.css';
import './css/tui.css';
import './css/scan.css';
import Popout from './widgets/Popout';
const styles = {
  container: styles => ({ ...styles, borderRadius: 0, height: '34px' }),
  control: styles => ({
    ...styles,
    backgroundColor: '#2d3446',
    boxShadow: 'none',
    borderBottom: 'none',
    border: 'none',
    borderRadius: 0,
    height: '34px',
    minHeight: 'unset',
    ':hover': { ...styles[':hover'], border: 'none' },
    ':focus': { ...styles[':focus'], border: 'none' },
    alignItems: 'start',
  }),
  valueContainer: styles => ({
    ...styles,
    backgroundColor: '#28283e',
    borderBottom: 'none',
    height: '34px',
    alignItems: 'start',
    padding: 0,
    paddingLeft: '5px',
  }),
  input: styles => ({ ...styles, color: 'WHITE' }),
  menu: styles => ({
    ...styles,
    color: 'rgb(80, 153, 255)',
    backgroundColor: '#2d3446',
  }),
  option: styles => ({
    ...styles,
    color: 'rgb(80, 153, 255)',
    backgroundColor: '#2d3446',
  }),
  multiValue: styles => ({ ...styles, backgroundColor: '#060d13' }),
  multiValueLabel: styles => ({
    ...styles,
    color: '#1890ff',
    backgroundColor: '#060d13',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: '#060d13',
    ':hover': { ...styles[':hover'], backgroundColor: '#060d13' },
  }),
  clearIndicator: styles => ({
    ...styles,
    padding: 0,
  }),
};

const boxTop = {
  padding: '5px 5px',
};
const News = ({ view }) => {
  const { rowStyle, colStyle } = basicStyle;
  const [searched, setSearched] = useState('');
  const [searchSymbols, setSearchSymbol] = useState([]);
  const [notifEnabled, setNotifEnabled] = useState(false);
  const [notifSound, setNotifSound] = useState(false);
  const [headlineOnly, setHeadlineOnly] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const handleNotifChange = e => {
    setNotifEnabled(e.target.checked);
    localStorage.setItem('newsNotif', JSON.stringify(e.target.checked));
  };
  const handleNotifSound = () => {
    setNotifSound(prev => {
      localStorage.setItem('notifSound', JSON.stringify(!prev));
      return !prev;
    });
  };
  const loadNotifState = () => {
    const state = JSON.parse(localStorage.getItem('newsNotif'));
    const soundState = JSON.parse(localStorage.getItem('notifSound'));
    if (state) {
      setNotifEnabled(state);
    }
    if (soundState) {
      setNotifSound(soundState);
    }
  };

  useEffect(() => {
    loadNotifState();
  }, []);
  const pageIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mh-50px"
    >
      <path
        opacity="0.4"
        d="M17 10H11C10.4 10 10 9.6 10 9V8C10 7.4 10.4 7 11 7H17C17.6 7 18 7.4 18 8V9C18 9.6 17.6 10 17 10ZM22 4V3C22 2.4 21.6 2 21 2H11C10.4 2 10 2.4 10 3V4C10 4.6 10.4 5 11 5H21C21.6 5 22 4.6 22 4ZM22 15V14C22 13.4 21.6 13 21 13H11C10.4 13 10 13.4 10 14V15C10 15.6 10.4 16 11 16H21C21.6 16 22 15.6 22 15ZM18 20V19C18 18.4 17.6 18 17 18H11C10.4 18 10 18.4 10 19V20C10 20.6 10.4 21 11 21H17C17.6 21 18 20.6 18 20Z"
        fill="currentColor"
      ></path>
      <path
        d="M8 5C8 6.7 6.7 8 5 8C3.3 8 2 6.7 2 5C2 3.3 3.3 2 5 2C6.7 2 8 3.3 8 5ZM5 4C4.4 4 4 4.4 4 5C4 5.6 4.4 6 5 6C5.6 6 6 5.6 6 5C6 4.4 5.6 4 5 4ZM8 16C8 17.7 6.7 19 5 19C3.3 19 2 17.7 2 16C2 14.3 3.3 13 5 13C6.7 13 8 14.3 8 16ZM5 15C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17C5.6 17 6 16.6 6 16C6 15.4 5.6 15 5 15Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
      <div style={{ maxWidth: '1501px', width: '100%', padding: '24px' }}>
        <Popout link="news" />
        <PageHeader>{pageIcon} News</PageHeader>
        <Row
          className="boxTop darkHeader"
          style={rowStyle}
          gutter={0}
          justify="start"
        >
          <Col lg={24} md={24} sm={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '10px auto',
                  flexDirection: view === 'drawer' ? 'column-reverse' : 'row',
                  width: '100%',
                }}
              >
                <div
                  id="newsInputs"
                  style={{
                    display: 'flex',
                    flexWrap: view === 'drawer' ? 'wrap' : 'unset',
                  }}
                >
                  <div style={{ width: 150 }}>
                    <CreatableSelect
                      isMulti
                      isClearable
                      value={searchSymbols.map(e => ({
                        label: e,
                        value: e,
                      }))}
                      onChange={value => {
                        setSearchSymbol(value.map(e => e.value.toUpperCase()));
                      }}
                      placeholder={'FILTER BY SYMBOLS'}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                      formatCreateLabel={inputText =>
                        `Watch "${inputText.toUpperCase()}"`
                      }
                      styles={styles}
                    />
                  </div>
                  <input
                    className="brdrinput tbox src study"
                    size="large"
                    name="searchInput"
                    style={{ margin: '0 10px' }}
                    onChange={e => {
                      setSearched(e.target.value.toLowerCase());
                    }}
                    label="Search"
                    placeholder="Search"
                  />
                  <input
                    defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                    className="adte cFr dateNews"
                    type="date"
                    id="start"
                    min="2022-03-01"
                    onChange={e => {
                      setSelectedDate(
                        moment(e.target.value).format('YYYY-MM-DD')
                      );
                    }}
                    name="cFROM"
                    style={{ marginTop: view === 'drawer' ? '10px' : 'unset' }}
                  ></input>
                </div>
                <div
                  style={{ float: 'right', alignSelf: 'end', display: 'flex' }}
                >
                  <Tooltip
                    title={`${
                      notifSound ? 'Disable' : 'Enable'
                    } Notifications Sound`}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        borderRadius: '100%',
                        width: '30px',
                        textAlign: 'center',
                        position: 'relative',
                        bottom: '4px',
                      }}
                      onClick={() => {
                        // setNotifSound(prev => !prev)
                        handleNotifSound();
                      }}
                    >
                      {notifSound ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          x="0px"
                          y="0px"
                          width="25px"
                          height="25px"
                          viewBox="0 0 32 32"
                          xmlSpace="preserve"
                        >
                          <path
                            fill="#ffffffaf"
                            d="M4,11v10h5l10,6V5L9,11H4z M17,8.532v14.935L9.554,19H6v-6h3.554L17,8.532z M21.657,21.657  l-1.414-1.414c2.339-2.34,2.339-6.146,0-8.486l1.414-1.414C24.775,13.462,24.775,18.538,21.657,21.657z M24.485,24.485l-1.414-1.414  c3.899-3.899,3.899-10.243,0-14.143l1.414-1.414C29.164,12.193,29.164,19.807,24.485,24.485z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          x="0px"
                          y="0px"
                          width="25px"
                          height="25px"
                          viewBox="0 0 32 32"
                          xmlSpace="preserve"
                        >
                          <path
                            fill="#ffffffaf"
                            d="M21,15.242l2-2V27l-8.598-5.159l1.458-1.458L21,23.468V15.242z M27.707,5.707l-22,22  l-1.414-1.414L9.586,21H8V11h5l10-6v2.586l3.293-3.293L27.707,5.707z M21,9.586V8.532L13.554,13H10v6h1.586L21,9.586z"
                          />
                        </svg>
                      )}
                    </div>
                  </Tooltip>
                  {view !== 'drawer' && (
                    <Tooltip title={`Show headline only`}>
                      <Checkbox
                        style={{
                          top: '-3px',
                          marginLeft: '4px',
                          marginBottom: '10px',
                          alignSelf: view === 'drawer' ? 'end' : 'unset',
                        }}
                        checked={headlineOnly}
                        onChange={e => {
                          setHeadlineOnly(prev => !prev);
                        }}
                      >
                        <span
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          Headlines only
                        </span>
                      </Checkbox>
                    </Tooltip>
                  )}

                  <Tooltip title={`Enable News Notifications`}>
                    <Checkbox
                      //className="UFN"
                      style={{
                        top: '-3px',
                        marginLeft: '4px',
                        marginBottom: '10px',
                        alignSelf: view === 'drawer' ? 'end' : 'unset',
                      }}
                      checked={notifEnabled}
                      onChange={handleNotifChange}
                    >
                      <span
                        style={{
                          fontSize: '10px',
                        }}
                      >
                        Enable Notifications
                      </span>
                    </Checkbox>
                  </Tooltip>
                </div>
              </div>

              <Row>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{
                    ...colStyle,
                    padding: window.innerWidth < 900 ? '0px' : '',
                  }}
                >
                  <NewsTable
                    searched={searched}
                    symbols={searchSymbols}
                    view={view}
                    notifEnabled={notifEnabled}
                    date={selectedDate}
                    headlineOnly={headlineOnly}
                    notifSound={notifSound}
                  />
                </Col>
              </Row>
              <span style={{ color: '#788195' }}>
                How to read Headline Sentiment: <br />
                Purple is neutral / Red is bearish / Green is bullish
                <br />% the percentage is of how bearish/bullish the headline is
              </span>
            </div>
          </Col>
        </Row>
      </div>{' '}
    </div>
  );
};

export default News;
