import React from 'react';

const ChatFloat = ({ onClick }) => (
  <div
    style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      zIndex: 999,
    }}
  >
    <div
      style={{
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        backgroundColor: '#7c5adc5c',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
      }}
      onClick={onClick}
    >
      <i
        className="ion-chatbox-working"
        style={{ color: 'white', fontSize: '25px' }}
      />
    </div>
  </div>
);

export default ChatFloat;
