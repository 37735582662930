import asyncComponent from '../helpers/AsyncFunc';
const routes = [
  {
    path: 'upgrade',
    component: asyncComponent(() => import('./containers/upgrade')),
  },
  {
    path: 'template',
    component: asyncComponent(() => import('./containers/page/template')),
  },
  {
    path: 'analysts',
    component: asyncComponent(() => import('./containers/analysts')),
  },
  {
    path: 'welcome',
    component: asyncComponent(() => import('./containers/welcome')),
  },
  {
    path: 'backtest',
    component: asyncComponent(() => import('./containers/backtest_page')),
  },

  {
    path: 'strategies',
    component: asyncComponent(() => import('./containers/page/strategies')),
  },
  {
    path: 'backtester',
    component: asyncComponent(() => import('./containers/backtest_page')),
  },
  {
    path: 'radar',
    component: asyncComponent(() => import('./containers/radar')),
  },
  {
    path: 'smartentry',
    component: asyncComponent(() => import('./containers/smartentry')),
  },
  {
    path: 'deepstrike',
    component: asyncComponent(() => import('./containers/page/deepstrike')),
  },
  {
    path: 'live',
    component: asyncComponent(() => import('./containers/optionslive')),
    //component: asyncComponent(() => import('./containers/live')),
  },
  {
    path: 'flow',
    component: asyncComponent(() => import('./containers/optionflow')),
  },
  {
    path: 'news',
    component: asyncComponent(() => import('./containers/news')),
  },
  {
    path: 'signals',
    component: asyncComponent(() => import('./containers/signals/signals')),
  },
  {
    path: 'exposure',
    component: asyncComponent(() => import('./containers/exposurelevels')),
  },
  {
    path: 'overview',
    component: asyncComponent(() => import('./containers/overview')),
  },
  {
    path: 'chart-setups',
    component: asyncComponent(() => import('./containers/scanner')),
  },
  {
    path: 'insights',
    component: asyncComponent(() => import('./containers/optionleaders')),
  },
  {
    path: 'insiders',
    component: asyncComponent(() => import('./containers/insiders')),
  },
  {
    path: 'darkpools',
    component: asyncComponent(() => import('./containers/darkpools')),
  },
  {
    path: 'dashboard',
    component: asyncComponent(() => import('./containers/Dashboard')),
  },
  {
    path: 'darkpool',
    component: asyncComponent(() => import('./containers/darkpools')),
  },
  {
    path: 'chart',
    component: asyncComponent(() => import('./containers/datachart')),
  },
  {
    path: 'technicals',
    component: asyncComponent(() => import('./containers/aifeed')),
  },
  {
    path: 'riskreward',
    component: asyncComponent(() => import('./containers/page/riskreward')),
  },
  {
    path: 'option-calculator',
    component: asyncComponent(() => import('./containers/optioncalculator')),
  },
  {
    path: 'study',
    component: asyncComponent(() => import('./containers/study')),
  },
  {
    path: 'chain_old',
    component: asyncComponent(() => import('./containers/study')),
  },
  {
    path: 'chain',
    component: asyncComponent(() => import('./containers/page/chain')),
  },
  {
    path: 'ticker/:tickerSymbol',
    component: asyncComponent(() => import('./containers/page/ticker')),
  },
  {
    //path: 'ticker',
    path: 'stock/:tickerSymbol',
    component: asyncComponent(() => import('./containers/page/ticker')),
  },
  {
    path: 'stock',
    component: asyncComponent(() => import('./containers/page/ticker')),
  },
  {
    path: 'journal',
    component: asyncComponent(() =>
      import('./containers/page/journalDashboard')
    ),
  },
  {
    path: 'watchlists',
    component: asyncComponent(() => import('./containers/WList')),
  },
  {
    path: 'aggreflow',
    component: asyncComponent(() => import('./containers/tickerFlow')),
  },
  {
    path: 'moneyline',
    component: asyncComponent(() => import('./containers/page/MoneyLine')),
  },
  {
    path: 'trade',
    component: asyncComponent(() => import('./containers/page/Trade')),
  },
  {
    path: 'zdte',
    component: asyncComponent(() => import('./containers/page/zdte')),
  },
  {
    path: 'chat',
    component: asyncComponent(() => import('./containers/chatg')),
  },
  {
    path: 'settings',
    component: asyncComponent(() => import('./containers/settings_page')),
  },
  {
    path: 'earnings',
    component: asyncComponent(() => import('./containers/page/earnings')),
  },
  {
    path: 'options',
    component: asyncComponent(() => import('./containers/optionsmarket')),
  },
  {
    path: 'alerts',
    component: asyncComponent(() => import('./containers/page/alerts_page')),
    //component: asyncComponent(() => import('./containers/alerts')),
  },
  {
    path: 'guide',
    component: asyncComponent(() => import('./containers/page/guide')),
    //component: asyncComponent(() => import('./containers/alerts')),
  },

  // {
  //   path: 'alertsx',
  //  component: asyncComponent(() => import('./containers/alerts')),
  // },
  // {
  //   path: 'aifeed',
  //   //component: asyncComponent(() => import('./containers/signals/signals')),
  //   component: asyncComponent(() => import('./containers/aifeed')),
  // },
  // {
  //   path: 'chat',
  //   component: asyncComponent(() => import('./containers/optionsmarket')),
  // },

  // {
  //   path: 'analyzef',
  //   component: asyncComponent(() => import('./containers/analyzeflow')),
  // },

  // {
  //   path: 'chat',
  //   component: asyncComponent(() => import('./containers/chatparty')),
  // },

  // {
  //   path: 'githubSearch',
  //   component: asyncComponent(() => import('./containers/GithubSearch'))
  // },
  // {
  //   path: 'alertsx',
  //   component: asyncComponent(() => import('./containers/Watchlist')),
  // },
  /*
   
   {
    path: 'fin-analyst',
    component: asyncComponent(() => import('./containers/Analyst')),
  },
  {
    path: 'breakouts-',
    component: asyncComponent(() => import('./containers/breakouts')),
  },
  
  
  {
    path: 'agent-',
    component: asyncComponent(() => import('./containers/agent')),
  },
  {
    path: 'tradeideas-',
    component: asyncComponent(() => import('./containers/tradeIdeas')),
  },

 
  {
    path: 'tests-',
    component: asyncComponent(() => import('./containers/tests_page')),
  },

  {
    path: 'chat1-',
    component: asyncComponent(() => import('./containers/chat')),
  },
   {
    path: 'analyst',
    component: asyncComponent(() => import('./containers/analystRatings')),
  },
  {
    path: 'crypto-',
    component: asyncComponent(() => import('./containers/signals/signals')),
  },
  {
    path: 'performance2-',
    component: asyncComponent(() => import('./containers/flowtest')),
  },
  {
    path: 'performance-',
    component: asyncComponent(() => import('./containers/performance')),
  },
  {
    path: 'blank_page-',
    component: asyncComponent(() => import('./containers/blankPage')),
  },
  {
    path: 'portfolio-',
    component: asyncComponent(() => import('./containers/portfolio')),
  },
  {
    path: 'roadmap',
    component: asyncComponent(() => import('./containers/roadmap')),
  }, 
  {
    path: 'prophet',
    component: asyncComponent(() => import('./containers/flowprophet')),
  }, 
  {
    path: 'ab',
    component: asyncComponent(() => import('./containers/ab')),
  },
  

    // {
  //   //this is bad by yon
  //   path: 'backtest2',
  //   component: asyncComponent(() => import('./containers/backtest')),
  // },
  */
];
export default routes;
