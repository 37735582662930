import React, { Component } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import IntlMessages from '@isomorphic/shared/isomorphic/components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import './topbar.css';
import { timeDifference } from '../../helpers/utility';
import { addNotification } from '../../redux/notifications/actions';
import {
  SparklinesLine,
  Sparklines,
  SparklinesSpots,
  SparklinesNormalBand,
} from 'react-sparklines';
const sampleData100 = [5, 10, 5, 20, 8, 15, 20, 8, 15, 20, 8, 15];
const bandMin = 10;
const bandMax = 115;

var TimerUpdate;
class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);

    this.state = {
      visible: false,
      demoNotifications: [],
      notifications: [],
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
    this.readNotifications();
  }

  async getNotifications(t) {
    let url = `https://ab.tradeui.com/api/alerts.php?action=get_mb&tkn=${t}`;
    let headers = {
      method: 'GET',
      cache: 'no-cache',
    };
    const res = await fetch(url, headers);
    let result = await res.json();
    return result;
  }

  async readNotifications() {
    let token = localStorage.getItem('id_token');
    let data = await this.getNotifications(token);
    if (data.error) {
      return;
    }

    let notifications = [];
    for (let i = 0; i < data.length; i++) {
      let notification = data[i];
      let date = notification['post_date'];
      let name = notification['post_title'];
      let notificationMesg = '';
      if (notification['post_content']) {
        notificationMesg = notification['post_content']
          .toString()
          .replace(/\\"/g, '"');
      }
      notifications.push({
        date: date,
        name: name,
        notification: notificationMesg,
      });
    }
    let newNotifs = notifications.filter(
      e => !this.props.notifications.map(e => e.date).includes(e.date)
    );
    //this.setState({ notifications: notifications });
    if (notifications.length > 0) {
      this.props.addNotification(newNotifs);
    }
  }

  componentDidMount() {
    this.readNotifications();
    TimerUpdate = setInterval(() => this.readNotifications(), 15 * 1000);
  }
  componentWillUnmount() {
    clearInterval(TimerUpdate);
  }
  render() {
    const { customizedTheme } = this.props;
    const content = (
      <TopbarDropdownWrapper
        className="topbarNotification"
        style={{ overflowY: 'scroll' }}
      >
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          {this.props.notifications.map((notification, i) => (
            <a
              key={i}
              className="isoDropdownListItem"
              // key={notification.id + Math.random()}
              href="#"
            >
              <div className="header">
                <i className="notificationIcon"></i>
                <Sparklines data={sampleData100} limit={15} margin={5}>
                  <SparklinesLine style={{ stroke: '#2991c8', fill: 'none' }} />
                  <SparklinesSpots />
                  <SparklinesNormalBand
                    style={{ fill: '#2991c8', fillOpacity: 0.1 }}
                    min={bandMin}
                    max={bandMax}
                  />
                </Sparklines>

                <h5>{timeDifference(notification.date)}</h5>
              </div>
              <h5>$TSLA{notification.name && console.log(notification)}</h5>
              <p>Bullish with Score 8{notification.notification}</p>
            </a>
          ))}
        </div>
        {/* <a className="isoViewAllBtn" href="# ">
          <IntlMessages id="topbar.viewAll" />
        </a> */}
      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-android-notifications"
            style={{ color: customizedTheme.textColor }}
          />
          <span>{this.props.notifications.length}</span>
        </div>
      </Popover>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    notifications: state.notifications.notifications,
  }),
  { addNotification }
)(TopbarNotification);
