//import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

class AuthHelper {
  login = async userInfo => {
    if (!userInfo.username || !userInfo.password) {
      return { error: 'user or password missing' };
    }
    userInfo.email = userInfo.username;
    //const authPage ='login';
    const authPage = 'token';

    return await SuperFetch.post(authPage, userInfo).then(response => {
      if (response.data && response.message) {
        if (response.data.status && response.data.status == '403') {
          // return response.message;
          //window.location.reload(false);
          // window.location.href = window.location.href + '?expired';
        }
      }

      return this.checkExpirity(response, response.token);
    });
  };
  async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: 'Token expired' };
    }
    return await SuperFetch.get('secret/test', { token })
      .then(response => ({
        status: '200',
        message: 'Success',
      }))
      .catch(error => ({ error: JSON.stringify(error) }));
  }
  checkExpirity = (Profile, token) => {
    if (!token) {
      return {
        error:
          'Failed to login. The username or password you entered is incorrect.',
      };
    }
    try {
      //const profile = jwtDecode(token);
      const profile = Profile;
      const expiredAt = profile.updated_at;

      // if (expiredAt > new Date().getTime()) {
      if (profile) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      console.log(e);

      return { error: 'Token Error' };
    }
  };
}
export default new AuthHelper();
