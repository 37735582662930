const setAiSignals = data => ({
  type: 'SET_AI_SIGNALS',
  payload: data,
});
const addAiSignal = data => ({
  type: 'ADD_AI_SIGNAL',
  payload: data,
});

export { setAiSignals, addAiSignal };
