const initState = {
  gapData: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_GAP_DATA':
      return {
        ...state,
        gapData: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
