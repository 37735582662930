const SPEECH_LANGUAGE_URI = 'SPEECH_LANGUAGE_URI';

export const GetSpeechLanguageURI = () => {
  if (window) {
    const speechLanguageURI = window.localStorage.getItem(SPEECH_LANGUAGE_URI);
    return JSON.parse(speechLanguageURI) ?? 'Google US English';
  }
};

export const SetSpeechLanguageURI = state => {
  if (window) {
    window.localStorage.setItem(SPEECH_LANGUAGE_URI, JSON.stringify(state));
  }
};

const SPEECH_LANGUAGE_SPEED = 'SPEECH_LANGUAGE_SPEED';
export const GetSpeechLanguageSpeed = () => {
  if (window) {
    const speechLanguageSpeed = window.localStorage.getItem(
      SPEECH_LANGUAGE_SPEED
    );
    return JSON.parse(speechLanguageSpeed) ?? 1;
  }
};

export const SetSpeechLanguageSpeed = state => {
  if (window) {
    window.localStorage.setItem(SPEECH_LANGUAGE_SPEED, JSON.stringify(state));
  }
};

const SPEECH_LANGUAGE_PITCH = 'SPEECH_LANGUAGE_PITCH';
export const GetSpeechLanguagePitch = () => {
  if (window) {
    const speechLanguagePitch = window.localStorage.getItem(
      SPEECH_LANGUAGE_PITCH
    );
    return JSON.parse(speechLanguagePitch) ?? 1;
  }
};

export const SetSpeechLanguagePitch = state => {
  if (window) {
    window.localStorage.setItem(SPEECH_LANGUAGE_PITCH, JSON.stringify(state));
  }
};
