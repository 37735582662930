import React, { useState, useEffect } from 'react';
import { WarningOutlined } from '@ant-design/icons';
const ApiDownNotice = () => {
  return (
    <div style={{ display: 'none' }} id="noticeDiv">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 0px',
          backgroundColor: '#151521',
        }}
      >
        <WarningOutlined
          style={{
            color: '#a50000',
            fontSize: '22px',
          }}
        />
        <p
          style={{
            fontSize: '16px',
            color: 'white',
            fontWeight: '#c9c9c9',
            margin: '0 5px',
          }}
          id="noticeTxt"
        >
          Server is currently down
        </p>
      </div>
    </div>
  );
};

export default ApiDownNotice;
