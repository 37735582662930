import { getDefaultPath } from '../../helpers/urlSync';
import actions, { getView } from './actions';
import { SetCollapsedPersist, GetCollapsedPersist } from './CollapsedPersist';
const preKeys = getDefaultPath();

const initState = {
  collapsed: GetCollapsedPersist(),
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  drawerIsOpen: JSON.parse(localStorage.getItem('DRAWER_STATE_XYZ')) || false,
  darkMode: localStorage.getItem('darkMode') || 'yes',
  isRole0: false,
  isRole1: false,
  isRole2: false,
  isRole3: false,
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      SetCollapsedPersist(!state.collapsed);
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case actions.CHANGE_THEME:
      return {
        ...state,
        darkMode: state.darkMode === 'yes' ? 'no' : 'yes',
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: [],
      };
    case actions.TOGGLE_DRAWER:
      return {
        ...state,
        drawerIsOpen: !state.drawerIsOpen,
      };

    case 'SET_IS_ROLE_3':
      return {
        ...state,
        isRole3: action.payload,
      };
    case 'SET_IS_ROLE_0':
      return {
        ...state,
        isRole0: action.payload,
      };
    case 'SET_IS_ROLE_1':
      return {
        ...state,
        isRole1: action.payload,
      };
    case 'SET_IS_ROLE_2':
      return {
        ...state,
        isRole2: action.payload,
      };

    default:
      return state;
  }
  return state;
}
