const COLLAPSED_PERSIST_KEY = 'COLLAPSED_PERSIST_KEY_XYZ';
export const GetCollapsedPersist = () => {
  if (window) {
    const drawerState = window.localStorage.getItem(COLLAPSED_PERSIST_KEY);
    return JSON.parse(drawerState) ?? false;
  }
};

export const SetCollapsedPersist = state => {
  if (window) {
    window.localStorage.setItem(COLLAPSED_PERSIST_KEY, JSON.stringify(state));
  }
};
