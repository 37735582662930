import React, { useEffect, useState, useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import App from './containers/App/DrawerApp';
import asyncComponent from './helpers/AsyncFunc';
//import Auth0 from './authentication/auth0';
let isA = false;
if (localStorage.getItem('pl')) {
  let rr = '';
  let asa = '';
  if (JSON.parse(localStorage.getItem('pl')).roles)
    rr = JSON.parse(localStorage.getItem('pl')).roles[0];
  if (JSON.parse(localStorage.getItem('pl')).n)
    asa = JSON.parse(localStorage.getItem('pl')).n[0];
  isA = asa == 'ab' ? true : false;
}

const RestrictedRoute = ({
  component: Component,
  isLoggedIn,
  isRole1,
  isRole2,
  isRole3,
  isRole0,
  ...rest
}) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);

  const checkPermission = useCallback(async () => {
    return new Promise(resolve => {
      let permissionsFetched = false;

      const pl = localStorage.getItem('pl')
        ? JSON.parse(localStorage.getItem('pl'))
        : null;
      if (pl && Array.isArray(pl.roles)) {
        const roleMatchFound = pl.roles.some(
          role => role.startsWith('pmpro_role') || role === 'tradeuipro'
        );
        permissionsFetched = roleMatchFound;
      } else {
        permissionsFetched = false;
      }
      setTimeout(() => {
        setHasPermission(permissionsFetched);
        setRenderComponent(true);
        resolve();
      }, 500);
    });
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (isLoggedIn) {
        await checkPermission();
      } else {
        setHasPermission(false);
        setRenderComponent(true);
      }
    };

    fetchPermissions();
  }, [isLoggedIn, checkPermission]);

  return (
    <Route
      {...rest}
      render={props => {
        if (!renderComponent) {
          return <div>Loading permissions...</div>;
        } else if (!isLoggedIn) {
          return <Redirect to="/signin" />;
        } else if (!hasPermission) {
          return <Redirect to="/upgrade" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const PublicRoutes = ({
  history,
  isLoggedIn,
  isRole1,
  isRole0,
  isRole2,
  isRole3,
}) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route exact path={'/upgrade'} component={App} />
        <Route
          exact
          path={'/404'}
          component={asyncComponent(() => import('./containers/Page/404'))}
        />
        <Route
          exact
          path={'/500'}
          component={asyncComponent(() => import('./containers/Page/500'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          exact
          path={'/signup'}
          component={asyncComponent(() => import('./containers/Page/signup'))}
        />
        <Route
          exact
          path={'/forgotpassword'}
          component={asyncComponent(() =>
            import('./containers/Page/forgotPassword')
          )}
        />
        <Route
          exact
          path={'/login'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route path="/stock" component={App} />
        <Route path="/alerts" component={App} />
        <Route exact path={'/api/register'} />
        <Route exact path={'/api/forgotpassword'} />
        <Route exact path={'/api/resetpassword'} />
        <Route
          exact
          path={'/earnings'}
          isLoggedIn={isLoggedIn}
          component={App}
        />
        <Route
          exact
          path={'/backtester'}
          isLoggedIn={isLoggedIn}
          component={App}
        />
        <Route
          exact
          path={'/smartentry'}
          isLoggedIn={isLoggedIn}
          component={App}
        />{' '}
        <Route
          exact
          path={'/deepstike'}
          isLoggedIn={isLoggedIn}
          component={App}
        />
        <Route exact path={'/radar'} isLoggedIn={isLoggedIn} component={App} />
        <Route
          exact
          path={'/'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route exact path={'/guide'} component={App} />
        <Route exact path={'/zdte'} component={App} />
        {/* <Route exact path={'/moneyline'} component={App} /> */}
        <Route exact path={'/performance'} component={App} />
        <Route exact path={'/performance2'} component={App} />
        <Route exact path={'/portfolio'} component={App} />
        {/* <Route exact path={'/journal'}  component={App}  /> */}
        <Route exact path={'/news'} isLoggedIn={isLoggedIn} component={App} />
        <Route
          exact
          path={'/overview'}
          isLoggedIn={isLoggedIn}
          component={App}
        />
        <RestrictedRoute
          exact
          path="/moneyline"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          exact
          path="/trade"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path={'/options'}
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          exact
          path="/template"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/analysts"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        {/* 
        <RestrictedRoute
          exact
          path="/backtester"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        /> */}
        <RestrictedRoute
          exact
          path="/strategies"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          exact
          path="/backtest"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/darkpools"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          component={App}
          path="/flow"
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/chart-setups"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/exposure"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/insights"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/insiders"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/watchlists"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/study"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/chart"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/chain_old"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/chain"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/signals"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/scanner"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/riskreward"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/option-calculator"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/aggreflow"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute path="/live" component={App} isLoggedIn={isLoggedIn} />
        <RestrictedRoute
          path="/prophet"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/aifeed"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/chat"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/tests"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        {/* By omitting the path attribute on line:109, we tell React Router to fallback to the provided component. */}
        {/* <RestrictedRoute
          exact
          path="/alerts"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        /> */}
        <RestrictedRoute
          exact
          path="/settings"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          exact
          path="/tradeideas"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          exact
          path="/opal"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        <RestrictedRoute
          path="/welcome"
          component={App}
          isLoggedIn={isLoggedIn}
          isRole0={isRole0}
          isRole1={isRole1}
          isRole2={isRole2}
          isRole3={isRole3}
        />
        {/* <Route exact path={'/crypto'} component={App} /> */}
        <RestrictedRoute path="/ab" component={App} isLoggedIn={isA} />
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: localStorage.getItem('id_token') !== null,
  isRole0: state.App.isRole0,
  isRole1: state.App.isRole1,
  isRole2: state.App.isRole2,
  isRole3: state.App.isRole3,
}))(PublicRoutes);
