const getLists = uid => ({
  type: 'GET_LISTS',
  payload: uid,
});

const getStocks = listId => ({
  type: 'GET_STOCKS',
  payload: listId,
});

export { getLists, getStocks };
