import React from 'react';
import Tooltip from '@isomorphic/shared/isomorphic/components/uielements/tooltip';
import { Row, Col } from 'antd';
import basicStyle from '../../settings/basicStyle';
import './topbar.css';
import UpArrow from './UpArrow';
import DownArrow from './DownArrow';

class SocialWNew extends React.Component {
  constructor() {
    super();
    this.state = { color: 'red' };
  }

  render() {
    const { rowStyle, widgetStyle } = basicStyle;
    const { socialData } = this.props;
    const { st = [], wsb = [], sizz = [], pmar = {}, gapp = [] } =
      socialData || {};

    const SocialWidget = (
      <Row gutter={0}>
        <div style={{ padding: '6px', paddingBottom: '0px' }}>
          <Col lg={12} md={24} sm={24} xs={24} style={widgetStyle}>
            <div
              id="wsbDiv"
              className="socialB"
              style={{ padding: '5px', overflow: 'hidden' }}
            >
              <Tooltip title={`Stocks trending on r/Wallstreetbets`}>
                <span className="socialnet round" style={{ color: 'white' }}>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mh-50px wwico"
                  >
                    <path
                      opacity="0.3"
                      d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
                      fill="#8b70ec"
                    ></path>
                    <path
                      d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
                      fill="#8b70ec"
                    ></path>
                  </svg>{' '}
                  Wallstreetbets
                </span>
              </Tooltip>
              <br />
              <div id="wsbDivC">
                {wsb.length > 0 ? (
                  wsb.map((ee, index) => (
                    <React.Fragment key={`${ee[0]}_${index}`}>
                      <div
                        key={ee[0]}
                        title={`${ee[0]} has ${ee[1]} mentions`}
                        className="spS stickr"
                      >
                        <a
                          target="_blank"
                          className="secondaryColor"
                          href={`/stock/${ee[0]}`}
                        >
                          {ee[0]}
                        </a>
                      </div>
                      <div
                        style={{ width: '50px', display: 'inline' }}
                        key={ee[0] + '_'}
                        className="mentions"
                      >
                        {ee[1]}
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <span>No data available</span>
                )}
              </div>
            </div>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24} style={widgetStyle}>
            <div
              id="stDiv"
              className="socialB"
              style={{ padding: '5px', overflow: 'hidden' }}
            >
              <Tooltip title={`Stocks trending on StockTwits`}>
                <span className="round socialnet" style={{ color: 'white' }}>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mh-50px wwico"
                  >
                    <path
                      opacity="0.3"
                      d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
                      fill="#8b70ec"
                    ></path>
                    <path
                      d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
                      fill="#8b70ec"
                    ></path>
                  </svg>{' '}
                  Stocktwits
                </span>
              </Tooltip>
              <br />
              <div id="stDivC">
                {st.length > 0 ? (
                  st.slice(0, 20).map(ee => (
                    <span key={ee.symbol} className="spS stickr">
                      <a
                        className="secondaryColor"
                        target="_blank"
                        href={`/stock/${ee.symbol}`}
                      >
                        {ee.symbol}
                      </a>
                    </span>
                  ))
                ) : (
                  <span>No data available</span>
                )}
              </div>
            </div>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24} style={widgetStyle}>
            <div
              id="sizzDiv"
              className="socialB"
              style={{ padding: '5px', overflow: 'hidden' }}
            >
              <Tooltip title={`Gap Ups and Downs`}>
                <span className="round socialnet" style={{ color: 'white' }}>
                  {/* <i
                    style={{ color: '#ff8100' }}
                    className="ion-android-alarm-clock"
                  ></i>{' '} */}
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    fill="#8b70ec"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mh-50px wwico"
                  >
                    <path
                      opacity="0.3"
                      d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                      fill="#8b70ec"
                    ></path>
                    <path
                      d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                      fill="#8b70ec"
                    ></path>
                  </svg>
                  Gap Up
                </span>
              </Tooltip>
              <br />
              <div id="sizzDivC">
                {gapp.length > 0 ? (
                  gapp
                    .filter(ee => ee[1] > 0)
                    .map(ee => (
                      <span key={ee[0]} className="spS stickr">
                        <a
                          className="secondaryColor"
                          target="_blank"
                          href={`/stock/${ee[0]}`}
                        >
                          <span
                            style={{
                              color: ee[1] > 0 ? '#4dfa7b' : '#ff7070',
                              marginRight: '4px',
                            }}
                          >
                            {ee[1] > 0 ? <UpArrow /> : <DownArrow />}
                          </span>
                          {ee[0]}
                          <span
                            style={{
                              color: ee[1] > 0 ? '#4dfa7b' : '#ff7070',
                              marginLeft: '4px',
                            }}
                          >
                            {ee[1]}%
                          </span>
                        </a>
                      </span>
                    ))
                ) : (
                  <span>No data available</span>
                )}
              </div>
            </div>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24} style={widgetStyle}>
            <div
              id="sizzDiv"
              className="socialB"
              style={{ padding: '5px', overflow: 'hidden' }}
            >
              <Tooltip title={`Gap Ups and Downs`}>
                <span className="round socialnet" style={{ color: 'white' }}>
                  <svg
                    style={{ transform: 'scaleY(-1)' }}
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    fill="#8b70ec"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mh-50px wwico"
                  >
                    <path
                      opacity="0.3"
                      d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                      fill="#8b70ec"
                    ></path>
                    <path
                      d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                      fill="#8b70ec"
                    ></path>
                  </svg>
                  Gap Down
                </span>
              </Tooltip>
              <br />
              <div id="sizzDivC">
                {gapp.length > 0 ? (
                  gapp
                    .filter(ee => ee[1] < 0)
                    .reverse()
                    .map(ee => (
                      <span key={ee[0]} className="spS stickr">
                        <a
                          className="secondaryColor"
                          target="_blank"
                          href={`/stock/${ee[0]}`}
                        >
                          <span
                            style={{
                              color: ee[1] > 0 ? '#4dfa7b' : '#ff7070',
                              marginRight: '4px',
                            }}
                          >
                            {ee[1] > 0 ? <UpArrow /> : <DownArrow />}
                          </span>
                          {ee[0]}
                          <span
                            style={{
                              color: ee[1] > 0 ? '#4dfa7b' : '#ff7070',
                              marginLeft: '4px',
                            }}
                          >
                            {ee[1]}%
                          </span>
                        </a>
                      </span>
                    ))
                ) : (
                  <span>No data available</span>
                )}
              </div>
            </div>
          </Col>
        </div>
        {/* /r/Wallstreetbets & Stocktwits most talked about stocks (Hourly Updates)  */}
      </Row>
    );

    return SocialWidget;
  }
}

export default SocialWNew;
