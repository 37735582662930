import { setSWB, setUF } from './actions';

const initState = {
  swbData: [],
  ufData: [],
  fetchData: undefined,
  chartTicker: null,
  chartData: null,
  tickers: [],
  tickersOut: [],
  sectors: [],
  wlTickers: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SWB_DATA':
      return {
        ...state,
        swbData: action.payload,
      };
    case 'SET_UF_DATA':
      return {
        ...state,
        ufData: action.payload,
      };

    case 'SET_FETCH_DATA':
      return {
        ...state,
        fetchData: action.payload,
      };
    case 'SET_CHART_TICKER':
      return {
        ...state,
        chartTicker: action.payload,
      };
    case 'SET_CHART_DATA':
      return {
        ...state,
        chartData: action.payload,
      };
    case 'SET_TICKERS':
      return {
        ...state,
        tickers: action.payload,
      };
    case 'SET_TICKERS_OUT':
      return {
        ...state,
        tickersOut: action.payload,
      };
    case 'SET_SECTORS':
      return {
        ...state,
        sectors: action.payload,
      };
    case 'SET_WL_TICKERS':
      return {
        ...state,
        wlTickers: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
