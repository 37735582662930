export function anotherTimezoneByUTCOffset(offset) {
  //Get local time
  const local = new Date();
  //Convert it to utc time in ms
  const utc = local.getTime() + local.getTimezoneOffset() * 60000;
  console.log('utc', utc);
  //Create destination by utc offset
  const dest = new Date(utc + 3600000 * offset);
  return dest;
}

function MarketClockData(statusString, hours, minutes, seconds) {
  let timeString;
  if (hours >= 24) {
    let days = Math.floor(hours / 24);
    let modHours = hours % 24; 

    let d = days === 0 ? '' : `${days}d`;
    timeString = `${d} ${modHours}:${minutes < 10 ? '0' + minutes : minutes}`;
  } else { 
    timeString = `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  return [statusString, timeString];
}

export function getCountdown(currentDate) {
  const day = currentDate.getDay();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = 59 - currentDate.getSeconds();

  const currentTimeInMinutes = hours * 60 + minutes;
  const marketOpenTimeInMinutes = 9 * 60 + 30;
  const marketCloseTimeInMinues = 16 * 60;

  if (day === 5 && currentTimeInMinutes >= marketCloseTimeInMinues) {
    // If it's Friday after market close, next open is Monday
    const remainingMinutes = 
      (24 * 60 - currentTimeInMinutes) + // Rest of Friday
      (48 * 60) + // Saturday and Sunday
      marketOpenTimeInMinutes; // Time until market opens Monday
    const openUntilHours = Math.floor(remainingMinutes / 60);
    const openUntilMinutes = remainingMinutes % 60;
    return MarketClockData('to open', openUntilHours, openUntilMinutes, seconds);
  }

  if (day >= 1 && day <= 5) {
    //Market Is open already
    if (
      currentTimeInMinutes >= marketOpenTimeInMinutes &&
      currentTimeInMinutes < marketCloseTimeInMinues
    ) {
      //Already return market is open and calculate close until
      const remainingMinutes = marketCloseTimeInMinues - currentTimeInMinutes;
      const closeUntilHours = Math.floor(remainingMinutes / 60);
      const closeUntilMinutes = remainingMinutes % 60;
      return MarketClockData(
        'to close',
        closeUntilHours,
        closeUntilMinutes,
        seconds
      );
    }

    //Market has not opened yet, but will open soon
    if (currentTimeInMinutes < marketOpenTimeInMinutes) {
      const remainingMinutes = marketOpenTimeInMinutes - currentTimeInMinutes;
      const openUntilHours = Math.floor(remainingMinutes / 60);
      const openUntilMinutes = remainingMinutes % 60;
      return MarketClockData(
        'to open',
        openUntilHours,
        openUntilMinutes,
        seconds
      );
    }
    if (currentTimeInMinutes === marketCloseTimeInMinues) {
      return MarketClockData('market closing', '');
    }
    //Market has closed, so calculate for the next day :D
    if (currentTimeInMinutes > marketCloseTimeInMinues) {
      const remainingMinutes =
        24 * 60 - currentTimeInMinutes + marketOpenTimeInMinutes;
      const openUntilHours = Math.floor(remainingMinutes / 60);
      const openUntilMinutes = remainingMinutes % 60;

      return MarketClockData(
        'to open',
        openUntilHours,
        openUntilMinutes,
        seconds
      );
    }
  }

  //added 24 h
  if (day === 0) {
    const remainingMinutes =
      24 * 60 - currentTimeInMinutes + marketOpenTimeInMinutes;
    const openUntilHours = Math.floor(remainingMinutes / 60);
    const openUntilMinutes = remainingMinutes % 60;

    return MarketClockData(
      'to open',
      openUntilHours,
      openUntilMinutes,
      seconds
    );
  }
  //added 24+24=48 hours
  if (day === 6) {
    const remainingMinutes =
      48 * 60 - currentTimeInMinutes + marketOpenTimeInMinutes;
    const openUntilHours = Math.floor(remainingMinutes / 60);
    const openUntilMinutes = remainingMinutes % 60;

    return MarketClockData(
      'to open',
      openUntilHours,
      openUntilMinutes,
      seconds
    );
  }

  // Default return to ensure we always show countdown
  const remainingMinutes = marketOpenTimeInMinutes - currentTimeInMinutes;
  const openUntilHours = Math.floor(remainingMinutes / 60);
  const openUntilMinutes = remainingMinutes % 60;
  return MarketClockData('to open', openUntilHours, openUntilMinutes, seconds);
}