import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '@isomorphic/shared/isomorphic/components/uielements/popover';
import IntlMessages from '@isomorphic/shared/isomorphic/components/utility/intlMessages';
import userpic from '@isomorphic/shared/isomorphic/assets/images/truser.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';

const { logout } = authAction;
const wstatus = {
  fontSize: '11px',
  color: '#96a2c1',
  position: 'fixed',
  zIndex: '1000',
  bottom: '8px',
};
const PlanBlock = () => {
  const pl = localStorage.getItem('pl');
  if (pl && JSON.parse(pl) && JSON.parse(pl).roles) {
    const roles = JSON.parse(pl).roles;
    if (roles && roles[0]) {
      if (roles[0] === 'member') {
        return (
          <a className=" pblock" rel="noopener noreferrer" href="/upgrade">
            Free / Upgrade
          </a>
        );
        // /pmpro_role_6
        // if it includes pmpro_
      } else if (roles[0] == 'pmpro_role_6') {
        return (
          <a
            className=" pblock"
            target="_blank"
            rel="noopener noreferrer"
            href="https://tradeui.com/pricing"
          >
            Plan Trial
          </a>
        );
      } else if (roles[0].includes('pmpro_')) {
        return (
          <a
            className=" pblock"
            target="_blank"
            rel="noopener noreferrer"
            href="https://tradeui.com/pricing"
          >
            Paid Plan
          </a>
        );
      } else {
        return <span className=" pblock">{roles[0]}</span>;
      }
    } else if (roles && roles[1] && roles[1] == 'tradeuipro') {
      return <span className=" pblock">TradeUI Pro</span>;
    } else {
      return <span>Member</span>;
    }
  }
  return null;
};
class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.getEmail = this.getEmail.bind(this);
    //this.getPlan = this.getPlan.bind(this);
    this.since = this.since.bind(this);

    this.state = {
      visible: false,
    };
  }
  toggleMode2() {
   // console.log('togglemode');
  }
  logOut() {
    this.props.logout();
    localStorage.removeItem('pl');
    localStorage.removeItem('id_token');
    document.location.href = '/signin';
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  // getPlan() {
  //   const pl = localStorage.getItem('pl');
  //   if (pl && JSON.parse(pl) && JSON.parse(pl).roles) {
  //     const roles = JSON.parse(pl).roles;
  //     if (roles[0] === 'member') {
  //       return (
  //         <div>
  //           <a
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             href="https://tradeui.com/pricing"
  //           >
  //             Free (UPGRADE)
  //           </a>
  //         </div>
  //       );
  //     }
  //     return <span>{roles[0]}</span>;
  //   }
  //   return null;
  // }

  getEmail() {
    let pl = localStorage.getItem('pl');
    if (pl) {
      return JSON.parse(pl).user_email;
    } else {
      return {};
    }
  }
  credits() {
    let pl = localStorage.getItem('pl');
    let p = JSON.parse(pl);
    if (p && p.eC && p.sC) {
      let eC = JSON.parse(pl).eC;
      let sC = JSON.parse(pl).sC;
      if (eC == false) eC = 0;
      if (sC == false) sC = 0;
      return `${sC} sms & ${eC} emails alerts`;
    } else {
      return '';
    }
  }
  // video_intro(el) {
  //   console.log(el);
  //   document.onkeydown = function(evt) {
  //     evt = evt || window.event;
  //     if (evt.keyCode == 27) {
  //       document.getElementById('overlay2').style.visibility = 'hidden';
  //     }
  //   };
  //   el.style.visibility =
  //     el.style.visibility == 'visible' ? 'hidden' : 'visible';
  // }
  since() {
    let pl = localStorage.getItem('pl');
    let p = JSON.parse(pl);
    if (p && p.user_registered) {
      return JSON.parse(pl).user_registered.substring(0, 10);
    } else {
      return '';
    }
  }

  logot(props) {
    // 9dima this.props.logout
    //localStorage.removeItem('id_token')
    setTimeout(() => {
      localStorage.removeItem('id_token');
      localStorage.removeItem('pl');
      window.location.href = 'https://app.tradeui.com';
    }, 10);
  }
  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/account/"
        >
          {this.getEmail()}
        </a>
        <div className="isoDropdownLink">
          <PlanBlock />

          {/* {this.getPlan()} */}
        </div>
        {/* <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/account/"
        >
          {'Member since ' + this.since()}
        </a> */}
        {/* <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/account/"
        >
          {this.credits()}
        </a> */}

        {/* <hr
          style={{
            width: '95%',
            // backgroundColor: '#7252e6',
            height: 1,
            borderBottom: '3px solid #303047',
          }}
        /> */}

        <a
          className="isoDropdownLink"
          rel="noopener noreferrer"
          href="/settings"
        >
          Settings
        </a>
        <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/account/"
        >
          Manage Plan
        </a>
        <a
          className=' isoDropdownLink'
          href="https://tradeui.com/changelog"
          target="_blank"
          style={{ color: '#6770ab' }}
        > 
          New Features
        </a>{' '}
        <hr
          style={{
            width: '95%',
            // backgroundColor: '#7252e6',
            height: 1,
            borderBottom: '3px solid #303047',
          }}
        />
        {/* <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/updates/"
        >
          Updates
        </a> */}

        {/* <a
          className="isoDropdownLink"
          onClick={() => window.lsup.maximize()}
          target="_blank"
        >
          <IntlMessages id="topbar.help" />
         
        </a> */}
        <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/guest"
        >
          Join Community
        </a>
        <a
          className="isoDropdownLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://tradeui.com/discord"
        >
          Join Discord
        </a>
        <a
          className="isoDropdownLink"
          target="_blank"
          // onClick={() => this.video_intro(document.getElementById('overlay2'))}
          href="https://www.youtube.com/@tradeui"
        >
          How To Videos
        </a>
        <hr
          style={{
            width: '95%',
            // backgroundColor: '#7252e6',
            height: 1,
            borderBottom: '3px solid #303047',
          }}
        />
        {/* 
        <i className="ion-contrast"> {' Dark Mode'}</i>
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          Dark Mode
        </a> */}
        
        <a className="isoDropdownLink">
          {localStorage.getItem('tui_v') !== null
            ? 'Version ' + localStorage.getItem('tui_v')
            : ''}
        </a>
        
        <a
          className="isoDropdownLink"
          onClick={() => {
            localStorage.clear();
            window.location.href = 'https://app.tradeui.com';
          }}
          href="#"
        >
          <IntlMessages id="topbar.app" />
        </a>
        <a
          className="isoDropdownLink"
          onClick={() => {
            this.logOut();
          }}
          href="#"
        >
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div
          style={{ backgroundColor: 'transparent' }}
          className="isoImgWrapper"
        >
          {/* <img alt="user" src={userpic} /> */}
          <svg
            width="31"
            height="31"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mh-50px"
          >
            <path
              opacity="0.3"
              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
              fill="currentColor"
            ></path>
            <path
              d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
              fill="currentColor"
            ></path>
          </svg>
          {/* <span className="userActivity online" /> */}
        </div>
      </Popover>
    );
  }
}
export default connect(null, { logout })(TopbarUser);
