import React, { useEffect } from 'react';
import Notifications from '@isomorphic/shared/isomorphic/components/Feedback/Notification';
import { connect } from 'react-redux';
import { addNotification } from '../../redux/notifications/actions';

window.QuickNotifications = notification => {
  Notifications.open({
    message: notification.message,
    description: notification.description,
    duration: 2,
  });
};

const QuickNotifications = ({ addNotification }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.ab !== undefined) {
        window.ab.addEventListener('message', e => {
          // Add logics for checking for notification
          //showing it immediately
          //storing it to redux
        });
        clearInterval(interval);
      }
    }, 200);
  }, []);
  return <></>;
};

export default connect(null, { addNotification })(QuickNotifications);
