import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SocketConnected = () => {
  const [connectionStatus, setConnectionStatus] = useState(false);
  useEffect(() => {
    const abPuller = setInterval(() => {
      if (window.ab) {
        if (window.ab.readyState === WebSocket.OPEN) {
          setConnectionStatus(() => true);
        } else {
          setConnectionStatus(() => false);
        }
      }
    }, 100);

    return () => clearInterval(abPuller);
  }, []);
  if (connectionStatus) {
    return (
      <>
        <GreenDot
          className="dotp"
          style={{ marginLeft: '5px', top: '1px' }}
          title={'Connected'}
        />
      </>
    );
  } else {
    return <RedDot style={{ marginLeft: '5px' }} title={'Disconnected'} />;
  }
};

const RedDot = styled.div`
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
  position: relative;
  bottom: 4px;
`;

const GreenDot = styled.span`
  margin-right: 3px;
  width: 10px;
  height: 10px;
  position: relative;
  bottom: 4px;
  background: #67db52;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
`;

export default SocketConnected;
